.info-feedback {
  background-color: $blue-jeans-10;
  color: $blue-jeans-125;
  padding: 4px 8px 4px 18px;
  margin-bottom: 16px;
  position: relative;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 3px;
    height: 100%;
    background-color: $blue-jeans-125;
  }
}