.alert {
  border-radius: 16px;
  border: 1px solid $blue-jeans-50;
  background: rgb(204, 236, 253);

  .btn {
    color: $just-white;

    &:hover {
      background-color: darken($blue-jeans,10%);
    }
  }

  h5 {
    font-size: 20px;
    line-height: 24px;
  }

  span {
    font-size: 16px;
    line-height: 20px;
  }
}