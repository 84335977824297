.page-content {
  p {
    margin-bottom: 1em;
  }

  i {
    font-style: italic;
  }

  ul {
    li {
      padding-left: 20px;
      position: relative;

      &:before {
        content: ' ';
        position: absolute;
        left: 0;
        top: calc(16px - 1.5px);
        display: block;
        height: 3px;
        width: 10px;
        border-radius: 3px;
        margin-right: 8px;
        background-color: $space-cadet-25;
      }
    }
  }

  ol {
    list-style: auto;

    li {
      padding-left: 0;

      &:before {
        content: none;
      }
    }
  }

  hr {
    margin-block-start: 2em;
    margin-block-end: 2em;
  }
}