.background-reservation-page {
  position: absolute;
  max-width: 100%;
  overflow: hidden;
  top: -180px;
  z-index: -1;

  @include media-breakpoint-up(xl) {
    top: -330px;
  }


  svg {
    @include media-breakpoint-up(lg) {
      width: 100vw;
    }
  }
}

.reservation-page {
  .reservation-component {
    .background-container {

      @include media-breakpoint-up(lg) {
        display: none;
      }

      img {
        width: 100%;
        height: 230px;
        object-fit: cover;
      }
    }

    .form-wrapper {
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      background-color: $just-white;
      margin-top: -35px;
      width: 100%;
      padding: 24px;
      z-index: 10;
      position: relative;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        background-color: transparent;
      }
    }


    .reservation-accommodation-header {
      display: flex;
      margin-bottom: 40px;

      .boxed-icon {
        margin-right: 1em;
      }

      a, small {
        display: block;
      }

      a {
        color: $text-color;
        text-decoration: none;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 4px;
        &:hover {
          text-decoration: underline;
        }
      }

      small {
        color: $santas-gray;
        font-size: 12px;
        line-height: 12px;
      }
    }

    .ts-wrapper {
      display: flex;
      align-items: center;
    }

    .ts-control {
      padding: 0;
    }

    .reservation-group-selector-wrapper {
      @include media-breakpoint-up(lg) {
        background-color: $blue-jeans-25;
        padding: 24px;
        border-radius: 16px;

        label {
          color: $text-color;
        }

        .ts-wrapper {
          border-color: $just-white;
        }
      }
    }

    .booking-date-widget {
      position: relative;

      .range-display-component {
        width: 100%;
      }

      .modal-component {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 100;
        width: 100%;
        background-color: $just-white;
        padding: 30px;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        box-shadow: rgba($text-color, .3) 0 0 20px 9999px;


        @include media-breakpoint-up(lg) {
          position: absolute;
          box-shadow: 0 8px 48px rgba(26, 30, 64, 0.12);
          border-radius: 25px;
          width: auto;
          top: 50px;
          min-height: 348px;
        }
      }
    }
  }

  .booking-prices-wrapper {
    position: relative;
    min-height: 200px;

    .booking-prices-loader {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background-color: rgba($just-white, .6);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1;
    }
  }
}