.home-block {
  padding-bottom: 130px;

  .text {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    margin-top: 2em;

    @include media-breakpoint-up(md) {
      padding-right: 7em;
    }

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }


    p {
      font-size: 18px;

      @include media-breakpoint-up(md) {
        font-size: 20px;
      }
    }
  }

  .image-wrapper {
    @include media-breakpoint-down(lg) {
      max-height: 500px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 440px;
    }
  }

  .filter-component {
    background-color: $just-white;
    border-radius: 24px;
    padding: 20px;
    display: flex;
    position: relative;
    margin-top: -130px;
    font-size: 16px;
    box-shadow: 0px 8px 48px rgba(26, 30, 64, 0.12);

    .ts-control, .filter-input input, .ts-dropdown-content .option{
      font-size: 16px;
    }

    .mb-3 {
      margin-bottom: 0 !important;
    }
  }
}