.timeline .timeline-item {
  position: relative;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
}
.timeline .timeline-item:last-child .timeline-line {
  bottom: 100%;
}
.timeline .timeline-line {
  display: block;
  content: " ";
  justify-content: center;
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  bottom: 0;
  transform: translate(50%);
  border-left-width: 1px;
  border-left-style: dashed;
  border-left-color: #E4E6EF;
}
.timeline .timeline-icon {
  z-index: 1;
  flex-shrink: 0;
  margin-right: 1rem;
}
.timeline .timeline-content {
  width: 100%;
  overflow: auto;
  margin-bottom: 1.5rem;
}
.timeline.timeline-center .timeline-item {
  align-items: center;
}
.timeline.timeline-center .timeline-item:first-child .timeline-line {
  top: 50%;
}
.timeline.timeline-center .timeline-item:last-child .timeline-line {
  bottom: 50%;
}

.timeline-label {
  position: relative;
}
.timeline-label:before {
  content: "";
  position: absolute;
  left: 51px;
  width: 3px;
  top: 0;
  bottom: 0;
  background-color: #EFF2F5;
}
.timeline-label .timeline-item {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 1.7rem;
}
.timeline-label .timeline-item:last-child {
  margin-bottom: 0;
}
.timeline-label .timeline-label {
  width: 50px;
  flex-shrink: 0;
  position: relative;
  color: #3F4254;
}
.timeline-label .timeline-badge {
  flex-shrink: 0;
  background: #ffffff;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  position: relative;
  margin-top: 1px;
  margin-left: -0.35rem;
  padding: 3px !important;
  border-radius: 50%;
  border: 4px solid $coral;
  outline: 4px solid $just-white;
}
.timeline-label .timeline-badge span {
  display: block;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  background-color: #EFF2F5;
}
.timeline-label .timeline-content {
  flex-grow: 1;
  padding-left: 1em;
}


.booking-event__info {
  font-size: 13px;
  line-height: 20px;
}

.booking-event__assets {
  .booking-event-asset {
    display: flex;
    align-items: center;

    svg {
      margin-right: 1em;
    }
  }
}