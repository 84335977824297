.user-widget {
  display: flex;
  align-items: center;
  position: relative;

  .user-image {
    width: 40px;
    height: 40px;
    background-color: $space-cadet-10;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;

    i {
      color: $space-cadet-75;
      font-size: 22px;
    }
  }

  .name {
    font-weight: bold;
    color: $space-cadet-75;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 6px;
  }

  .group-context {
    opacity: 0;
    animation: 1s ease-out .5s 1 fadeIn forwards;
    height: 18px;

    .ts-wrapper {
      background-color: transparent;
    }
  }

  .select-element {
    padding: 0;
    border: none;
    height: auto;

    &.ts-hidden-accessible {
      opacity: 0;
    }

    .ts-control {
      padding: 0;

      &:after {
        content: '\e90f';
        font-family: '#{$icomoon-font-family}';
        font-size: 10px;
        margin-left: 8px;
      }
    }

  }

  .user-menu-trigger {
    margin-left: 2em;
    transition: transform .325s;
    &:hover {
      transform: scale(.90);
    }
  }

  .user-menu {
    position: absolute;
    top: 60px;
    right: 0;
    z-index: 100;
    min-width: 260px;
    border-radius: 16px;
    background-color: $just-white;
    padding: 20px 20px 14px 20px;
    box-shadow: 0 8px 48px rgba(26, 30, 64, 0.12);

    hr {
      margin: 0 -20px;
    }

    .btn-logout {
      display: block;
      width: 100%;
      text-align: center;
      color: $space-cadet-75;
      font-weight: bold;
      text-decoration: none;
      padding: 14px 0 0 0;
      font-size: 16px;
      line-height: 16px;

      &:hover {
        color: $blue-jeans;
      }
    }
  }
}

ul.user-menu-list {
  list-style: none;

  li {
    padding: 6px 8px;
    border-radius: 8px;
    margin-bottom: 6px;
    transition: background-color .1s ease-in;
    cursor: pointer;
    user-select: none;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $blue-jeans-10;
      color: $blue-jeans;

      a, i {
        color: $blue-jeans;
      }
    }

    i {
      font-size: 20px;
      width: 20px;
      color: $space-cadet-75;
      margin-right: 8px;
      transition: color .1s ease-in;
    }

    a {
      text-decoration: none;
      color: $space-cadet-75;
      font-weight: bold;
      display: flex;
      align-items: center;
      transition: color .1s ease-in;
    }

    b {
      line-height: 1.2;
    }
  }
}