.image-wrapper {
  border-radius: 24px;
  overflow: hidden;
  position: relative;

  .aspect {
    width: 100%;
  }

  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}