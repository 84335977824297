.content-card {
  position: relative;
  display: block;
  background-color: $just-white;
  box-shadow: 0 8px 48px rgba(26, 30, 64, 0.12);
  border-radius: 16px;
  padding: 20px;
  text-decoration: none;
  transition: all .125s ease-in;

  &-more-padding {
    @include media-breakpoint-up(md) {
      padding: 40px;
    }
  }

  h6 {
    display: flex;
    align-items: center;

    i {
      color: $santas-gray;
      font-size: 20px;
      margin-right: .5em;
    }
  }

  hr {
    border: none;
    border-top: 1px solid $space-cadet-25;
  }

  &:not(.content-card-no-hover) {
    &:hover {
      transform: translate3d(0, -4px, 0);
    }
  }

  .content-card-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2em;
    color: $text-color;

    .boxed-icon {
      margin-right: 15px;

      + div {
        flex: 1;
      }
    }

    small {
      font-size: 12px;
      line-height: 12px;
      color: $santas-gray;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .reservations-status {
    flex: 0 0 100%;
    max-width: 100%;
    margin-top: 25px;

    .badge {
      display: inline;
    }
  }

  .content-card-content {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 0;
    color: $space-cadet-75;
    font-weight: normal;

    small {
      font-size: 12px;
      line-height: 12px;
      color: $santas-gray;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    > div {
      width: 50%;
    }
  }

  .content-card-footer {
    margin-top: 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .group-members {
      display: flex;

      .user-avatar {
        margin-left: -4px;
      }
    }
  }

  &-blue-bg {
    background: $blue-jeans-10-2;
    border: 1px solid $blue-jeans-25;

    .content-card-header {
      margin-bottom: 25px;
    }

    .content-card-content {
      gap: 15px 0;

      > div {
        background: $just-white;
        padding: 15px;

        &:nth-child(odd) {
          position: relative;
          border-radius: 8px 0 0 8px;

          &::after {
            content: '';
            position: absolute;
            display: block;
            background: $blue-jeans-10-2;
            width: 1px;
            height: calc(100% - 16px);
            right: 0;
            top: 8px;
          }
        }

        &:nth-child(even) {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }

  &-with-image {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    .content-card-image {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;

      @media screen and (max-width: 991.2px) {
        flex: 0 0 100%;
        max-width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px 0 0 16px;

        @media screen and (max-width: 991.2px) {
          display: none;
        }
      }
    }

    .content-card-info {
      flex: 1;
      padding: 30px;

      small {
        font-size: 12px;
        line-height: 12px;
        color: $santas-gray;
        font-weight: bold;
        text-transform: uppercase;
        margin-bottom: 0;
      }
    }

    .content-card-content {
      width: calc(100% + 15px);

      > div {
        width: auto;

        @media screen and (min-width: 992px) and (max-width: 1200px) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        @media screen and (min-width: 768px) and (max-width: 850px) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        @media screen and (max-width: 560px) {
          flex: 0 0 100%;
          max-width: 100%;
        }

        @media screen and (max-width: 400px) {
          text-align: center;
        }
      }

      .content-card-info-group {
        background: $athens-gray-10;
        height: 100%;
        padding: 10px 30px 8px;
        border-radius: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 400px) {
          padding: 15px 30px 13px;
        }

        .row {
          height: 100%;
          justify-content: center;
          align-items: center;

          @media screen and (max-width: 400px) {
            gap: 10px;
          }
        }

        &-with-icon {
          text-align: center;
          font-size: 24px;
          color: $text-color-alt;
          font-weight: bold;

          i {
            font-size: 24px;

            ~ * {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.cta-participate-in-group {
  display: block;
  background-color: $naples-yellow-25;
  box-shadow: 0 8px 48px rgba(26, 30, 64, 0.12);
  border-radius: 16px;
  padding: 20px;
  text-decoration: none;
  transition: all .125s ease-in;
  height: 100%;
  position: relative;
  overflow: hidden;

  .background-detail-price-list {
    position: absolute;
    z-index: 1;
    overflow: visible;
    top: -130%;
    right: 20%;
    left: auto;

    @include media-breakpoint-up(md) {
      top: -50%;
      right: -50%;
    }


    svg {
      width: 100%;
    }

    path {
       fill: $naples-yellow;
    }
  }

  p, button {
    z-index: 10;
    position: relative;
  }

  p {
    font-size: 25px;
    line-height: 40px;
    font-weight: bold;
    color: $copper-125;
    text-align: center;

    @include media-breakpoint-up(lg) {
      font-size: 35px;
      line-height: 50px;
      text-align: left;
    }
  }
}
