@-webkit-keyframes openAnimation {
  0% {
    transform: translate3d(0, 80px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}

@-webkit-keyframes openAnimationMobile {
  0% {
    transform: translate3d(0, 80px, 0) scale(.7);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(.7);
  }
}


#galleryWidget {
  position: relative;
  display: inline-block;
  width: 100%;

  .splide__sr {
    width: 1px;
    height: 1px;
    display: none;
  }

  .carousel-props, .carousel-labels {
    z-index: 10;
    position: absolute;
    bottom: 2px;
    right: 2px;

    @include media-breakpoint-up(md) {
      bottom: 20px;
      right: 20px;
    }

    strong {
      display: none;

      @include media-breakpoint-up(md) {
        display: flex;
      }
    }



    background: $just-white;
    border-radius: 16px;
    height: 56px;
    padding: 16px;
    font-size: 14px;
    display: flex;
    align-items: center;
    animation: openAnimationMobile .75s both ease-in;

    @include media-breakpoint-up(md) {
      animation: openAnimation .75s both ease-in;
    }



    strong {
      color: $space-cadet-75;
      text-transform: uppercase;
      font-weight: bold;
    }

    .share {
      font-weight: bold;
      display: flex;
      align-items: center;
      color: $space-cadet-75;

      i {
        font-size: 16px;
        color: $coral;
        margin-right: .75em;
      }
    }

    .prop-group + .share {
      &:before {
        content: ' ';
        width: 1px;
        height: 32px;
        display: inline-flex;
        background: $space-cadet-25;
        margin-right: 1.25em;
        margin-left: 1.25em;
      }
    }

    .prop-group {
      display: flex;
      align-items: center;
      margin-left: 1.5em;
      position: relative;

      i {
        color: $blue-jeans;
        font-size: 16px;
        margin-right: .25em;
      }
    }

    .prop-group + .prop-group {
      &:before {
        content: ' ';
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        left: -.9em;
        background: $blue-jeans-50;
      }
    }
  }

  .carousel-labels {
    background: transparent !important;

    right: unset !important;
    bottom: unset !important;
    top: 11px;
    left: 0px;

    @include media-breakpoint-up(md) {
      top: unset;
      left: 20px;
      bottom: 25px !important;
    }

    .prop-group {
      &:first-child {
        margin-left: 0;
      }

      img {
        width: 72px;
      }
    }
  }

  // splide overrides

  .splide__slide {

    height: 275px;

    @include media-breakpoint-up(md) {
      height: 400px;
    }

    @include media-breakpoint-up(lg) {
      height: 500px;
    }

    @include media-breakpoint-up(xl) {
      height: 600px;
    }

  }

  .splide__track {
    border-radius: 24px;
    overflow: hidden;
  }

  .splide__pagination {
    bottom: -1.5em;

    .splide__pagination__page {
      background: $mantis-50;

      &.is-active {
        background: $mantis-125;
      }
    }
  }

  .splide__arrow--prev {
    left: -15px;

    @include media-breakpoint-up(xl) {
      left: -30px;
    }

    .icon-arrow-left {
      font-size: 13px;

      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }

      .path1:before {
        color: $just-white;
      }
      .path2:before {
        color: $just-white;
        margin-left: 0;
      }
    }
  }

  .splide__arrow--next {
    right: -15px;

    @include media-breakpoint-up(xl) {
      right: -30px;
    }

    .icon-arrow-right {
      font-size: 13px;

      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }

      .path1:before {
        color: $just-white;
      }
      .path2:before {
        color: $just-white;
        margin-left: 1px;
      }
    }
  }

  .splide__arrow {
    background: $mantis;
    opacity: 1;
    height: 30px;
    width: 30px;

    @include media-breakpoint-up(xl) {
      height: 60px;
      width: 60px;
    }

    svg {
      fill: $just-white;
    }
  }

  .splide__slide {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
