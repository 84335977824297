.btn {
    display: inline-flex;
    min-width: max-content; // this fixes issue in Firefox :(
    justify-content: center;
    align-items: center;
    height: $button-md-height;
    line-height: $button-md-height;
    border-radius: $button-md-border-radius;
    padding: 0 24px;
    text-decoration: none;
    border: none;
    font-weight: bold;
    font-size: 16px;
    font-family: 'Raleway', sans-serif;
    cursor: pointer;

    &.btn-link {
        background-color: transparent;
        color: $button-primary-bg-color;
        padding: 0;

        &:hover {
            background-color: transparent;
        }
    }

    &.btn-primary {
        background-color: $button-primary-bg-color;
        color: $button-primary-color;

        &:hover {
            background-color: darken($button-primary-bg-color, 0.3);
        }
    }

    &.btn-secondary {
        background-color: $button-secondary-bg-color;
        color: $button-secondary-color;

        &:hover {
            background-color: darken($button-secondary-bg-color, 0.3);
        }
    }

    &.btn-outline-secondary {
        border: 1px solid $space-cadet-75;
        color:$space-cadet-75;
    }

    &.btn-accept {
        background-color: $button-accept-bg-color;
        color: $button-accept-color;

        &:hover {
            background-color: darken($button-accept-bg-color, 0.3);
        }
    }

    &.btn-reject {
        background-color: $button-reject-bg-color;
        color: $button-reject-color;

        &:hover {
            background-color: darken($button-reject-bg-color, 0.3);
        }
    }

    &.btn-with-icon {
        display: inline-flex;
        align-items: center;
        column-gap: 8px;
    }

    &.btn-icon {
        background-color: $space-cadet-25;
        border-radius: 50%;
        padding: 8px;
        height: 32px;
        width: 32px;

        i {
            color: $space-cadet-75;
            font-size: 14px;
        }
    }

    &.btn-sm {
        height: 32px;
        padding: 0 12px;
        border-radius: 12px;
    }

    &.btn-disabled {
        opacity: 0.5;
    }
}

.button-group {
    display: flex;

    hr {
        border-top: 0;
        border-right: 0;
        border-left: 1px solid $space-cadet-25;
        margin: 0 25px;
    }
}

.btn-move-to-optional-group-step {
    background-color: $naples-yellow-10;
    border: 2px solid $naples-yellow-10;
    border-radius: 16px;
    width: 100%;
    padding: 20px 85px 20px 20px;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    text-decoration: none;

    &:hover {
        border: 2px solid $naples-yellow-25;

        .arrow {
            border: 2px solid $naples-yellow-50;
        }
    }

    .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: $space-cadet;
    }

    .description {
        font-size: 16px;
        font-weight: lighter;
        line-height: 32px;
        color: $space-cadet-75;
        margin-top: 8px;
    }

    .arrow {
        position: absolute;
        right: 20px;
        top: calc(50% - 20px);
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background-color: $just-white;
        border: 2px solid $naples-yellow-25;

        i {
            font-size: 20px;

            .path1:before,
            .path2:before {
                color: $naples-yellow-75;
            }
        }
    }
}
