.account-menu {
  border-right: 1px solid $athens-gray;
  padding-right: 1em;
  li {
    margin-bottom: 1em;

    &.active {
      a {
        color: $blue-jeans;
      }
    }

    a {
      display: flex;
      text-decoration: none;
      color: $text-color;
      padding: 10px 20px;
      border-radius: 5px;

      &:hover {
        background-color: $blue-jeans-10;
      }

      .boxed-icon {
        margin-right: 1em;
      }
    }
  }
}