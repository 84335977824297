.pagination {
  display: flex;
  margin: 0 -10px;

  .page-item {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background-color: $coral-10;
    color: $coral;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    cursor: pointer;
    
    a, button {
      border: none;
      background-color: transparent;
      font-weight: bold;
      color: $coral;
      cursor: pointer;
      text-decoration: none;
      display: flex;
    }

    &:first-of-type, &:last-of-type {
      background-color: transparent;
      color: $text-color;

      a, button {
        color: $text-color;
      }
    }

    &.active {
      background-color: $coral;
      color: $just-white;

      a, button {
        color: $just-white;
      }
    }

    &.disabled {
      opacity: 0.3;
      cursor: not-allowed;

      a, button {
        cursor: not-allowed;
      }
    }

    .page-link {

    }
  }
}

.pagination-mobile {
  @include media-breakpoint-down(md) {
    .page-item {
      display: none;

      &:first-of-type, &:last-of-type, &.active, &.m-active {
        display: flex;
      }
    }
  }
}