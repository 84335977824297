.group-page, .account-page {
  color: $text-color;

  .group-detail-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3em;

    .group-detail-title {
      h5, p {
        color: $text-color;

        a {
          display: inline;
        }
      }

      p {
        color: $santas-gray;
      }
    }

    a {
      text-decoration: none;
      color: $santas-gray;
      display: flex;
      align-items: center;
      font-weight: normal;

      &:hover {
        color: darken($santas-gray, 10%);

        i {
          transform: rotate(-360deg);
        }
      }

      i {
        transition: all 5s linear;
        color: $santas-gray;
        margin-right: 8px;
      }
    }
  }

  .group-member-item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.requested-member {
      .user-avatar {
        outline: 1px dashed $santas-gray;
        opacity: .5;
      }
    }

    .user-avatar {
      margin-right: 1em;
    }

    small {
      display: block;
      color: $santas-gray;
      font-size: 10px;
      line-height: 10px;
    }

    .btn-group {
      display: flex;
    }

    .btn-approve, .btn-decline {
      border: none;
      border-radius: 5px;
      padding: 4px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 4px;
      cursor: pointer;

      i {
        font-size: 10px;
      }
    }

    .btn-approve {
      background-color: $mantis;
      color: $just-white;

      &:hover {
        background-color: darken($mantis, 10%);
      }
    }

    .btn-decline {
      background-color: $wild-watermelon;
      color: $just-white;

      &:hover {
        background-color: darken($wild-watermelon, 10%);
      }
    }
  }

  .user-widget {
    .user-menu {
      top: 35px;
    }
  }
}
