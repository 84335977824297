.coi {
  position: relative;

  &:after {
    position: absolute;
    right: -2px;
    top: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 14px;
    min-width: 14px;
    background-color: $coral;
    color: $just-white;
    content: attr(data-coi);
    font-family: Arial, sans-serif;
    font-size: 10px;
    font-weight: bold;
    outline: 1px solid white;
  }
}