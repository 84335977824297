.modal-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba($text-color, .16);
  display: none;

  @include media-breakpoint-up(md) {
    align-items: center;
  }

  &.modal-open {
    display: flex;

    .login-requirement-modal {
      background-color: $naples-yellow-25;
    }
  }

  .click-outside-trigger {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.export-nights-per-booking-modal {
  display: block;
  top: 50%;
  left: 50%;
  transform: translate(-100%, -100%);
  height: auto;
  width: auto;
}

.modal-wrapper.modal-open .modal {
  padding: 40px;
  background-color: $just-white;
  border-radius: 30px;
  z-index: 999999;

  @include media-breakpoint-up(md) {
    margin: 10%;
  }

  .btn-close-modal {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;

    i {
      transition: transform .325s ease-in-out;
      display: block;
      color: $space-cadet;
    }

    &:hover {
      i {
        transform: rotate(90deg);
      }
    }
  }
}

.modal.show {
  position: fixed;
  background-color: $just-white;
  height: 90%;
  width: 100%;
  border-radius: 30px;
  padding: 0;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .modal-content {
    flex-grow: 1;
    box-shadow: none !important;
  }

  @include media-breakpoint-up(md) {
    max-width: 600px;
  }

  .btn-close-modal {
    border: none;
    background-color: transparent;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;

    i {
      transition: transform .325s ease-in-out;
      display: block;
      color: $space-cadet;
    }

    &:hover {
      i {
        transform: rotate(90deg);
      }
    }
  }

  .scrollable {
    padding-right: 12px;
    margin-right: -16px;
  }
}

.confirm-delete-modal.show {
  height: 20%; // Make the confirmation modal smaller than 90%
  width: 100%; // Adjust width as needed
  border-radius: 15px; // Adjust radius if needed

  @include media-breakpoint-up(md) {
    max-width: 400px; // Ensure the width on larger screens is also controlled
  }
}

.login-requirement-modal {
  background-color: $naples-yellow-25;
  z-index: 0;
  overflow: hidden;

  &:before {
    content: ' ';
    background: $just-white;
    width: 200%;
    padding-bottom: 100%;
    left: -50%;
    top: 30%;
    border-radius: 50%;
    display: block;
    position: absolute;
    transition: transform 1s ease;
    z-index: -1;
  }

  .login-requirment-icon {
    width: 124px;
    height: 124px;
    border-radius: 50%;
    background-color: $naples-yellow-75;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    svg {
      width: 80px;
      height: 80px;
    }
  }
}

.modal.contact-manager-modal {
  height: 80vh;
  overflow: hidden;
  position: relative;


  #js-ivv-warning {
    color: $wild-watermelon;
  }

  @include media-breakpoint-down(md) {
    border-radius: 30px 30px 0 0 !important;
    padding: 20px !important;

    h4 {
      text-align: center;
    }

    .btn-close-modal {
      right: 10px !important;
    }

    .scrollable {
      padding-bottom: 100px;
    }
  }

  .scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 12px;
    height: 100%;
  }
}
