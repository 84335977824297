.form-check-label {
    flex-basis: 90%;
}

span::-webkit-scrollbar, ol::-webkit-scrollbar, ul::-webkit-scrollbar, pre::-webkit-scrollbar, div::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
}

span::-webkit-scrollbar-thumb, ol::-webkit-scrollbar-thumb, ul::-webkit-scrollbar-thumb, pre::-webkit-scrollbar-thumb, div::-webkit-scrollbar-thumb {
    background-color: $coral !important;
}
span::-webkit-scrollbar-track, ol::-webkit-scrollbar-track, ul::-webkit-scrollbar-track, pre::-webkit-scrollbar-track, div::-webkit-scrollbar-track {
    background-color: $coral-10 !important;
}

.scrollable {
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;

    &::-webkit-scrollbar {
        width: 0.2rem;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $coral;
        border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
        background-color: $coral-10;
        border-radius: 2px;
    }
}

