.registration-complete {
    .container {
        height: 100%;
    }

    .registration-complete-container {
        height: calc(100% - 48px);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .gif {
          width: 200px;
        }
    }

    .registration-count-down-redirect {
        font-weight: bold;
        font-size: 30px;
    }
}

.stepper-nav {
  .final {
    .stepper-icon {
      .stepper-check {
        color: #009ef7 !important;
        display: block !important;
      }
    }

    .stepper-title {
      color: #3f4254 !important;
    }

    .stepper-desc {
      color: #a1a5b7 !important;
    }

    &.done {
      .stepper-icon {
        background-color: #009ef7 !important;

        .stepper-check {
          color: white !important;
        }
      }

      .stepper-title {
        color: #7e8299 !important;
      }

      .stepper-desc {
        color: #b5b5c3 !important;
      }
    }
  }
}