.infobox {
  padding: 4px 12px;
  border-radius: 16px;
  color: $just-white;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  white-space: normal;
  overflow-x: auto;
  line-height: 28px;

  span {
    overflow: auto;
  }

  button.btn {
    float: right;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
  }

  &.green {
    background-color: $mantis-10;
    color: $mantis;
  }

  &.blue {
    background-color: $blue-jeans-10;
    color: $blue-jeans;
  }

  &.red {
    background-color: $wild-watermelon-10;
    color: $wild-watermelon;
  }

  &.orange {
    background-color: $coral-10;
    color: $coral;
  }

  &.yellow {
    background-color: $naples-yellow-10;
    color: $naples-yellow-125;
  }

  &.black {
    background-color: $text-color;
    color: $just-white;
  }

  &.grey {
    background-color: $athens-gray;
    color: $text-color;
  }
}
