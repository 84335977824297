.user-avatar {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  font-family: Arial, serif;
  border: 1px solid $just-white;

  &.green {
    background-color: $mantis-10;
    color: $mantis;
  }

  &.blue {
    background-color: $blue-jeans-10;
    color: $blue-jeans;
  }

  &.orange {
    background-color: $coral-10;
    color: $coral;
  }

  &.red {
    background-color: $wild-watermelon-10;
    color: $wild-watermelon;
  }

  &.yellow {
    background-color: $naples-yellow-10;
    color: $naples-yellow-125;
  }

  &.purple {
    background-color: $lavender-purple-10;
    color: $lavender-purple;
  }
}