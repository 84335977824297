.error-page {
  svg {
    max-width: calc(min(80vw, 400px));
  }
}

.error-code {
  font-size: 33vw;
  line-height: 33vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  color: rgb(222,238,246);
  z-index: -1;
  font-family: 'Bowlby One', sans-serif;
}

.invalid-feedback {

  color: $wild-watermelon;
  padding: 4px 8px;
  margin-bottom: 16px;
  position: relative;
}