.booking-mini {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }

  img {
    width: 100%;
    margin-bottom: 1em;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;

    @include media-breakpoint-up(lg) {
      width: 150px;
      margin-bottom: 0;
    }
  }

  a.h5 {
    color: $text-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  i {
    color: $blue-jeans !important;
  }
}

.booking-list {
  a {
    text-decoration: none;
    color: $text-color;

    &:hover {
      h5 {
        color: $blue-jeans;
      }
    }

    .booking-mini {
      margin-bottom: 1.5em;
      flex-direction: row;

      img {
        width: 50px;
        height: 50px;
        border-radius: 5px;
      }

      .text-domain {
        display: none;
      }

      h5, .h5 {
        font-size: 16px;
        line-height: 20px;
      }

      .text-date-range {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
}
