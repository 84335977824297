$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: '../../fonts' !default;

$icon-voucher: "\e93b";
$icon-cancel: "\e93a";
$icon-pen: "\e939";
$icon-sleeping-bag: "\e936";
$icon-night: "\e937";
$icon-house-tent: "\e938";
$icon-bell: "\e935";
$icon-exclamation: "\e934";
$icon-user: "\e92f";
$icon-cog: "\e930";
$icon-dots: "\e931";
$icon-transaction: "\e932";
$icon-star-outline: "\e933";
$icon-search-house: '\e908';
$icon-sign-tent: '\e90e';
$icon-camp-ground: '\e916';
$icon-hike: '\e919';
$icon-backpack: '\e928';
$icon-family-walk: '\e92b';
$icon-arrow-down-path1: '\e900';
$icon-arrow-down-path2: '\e901';
$icon-arrow-left-path1: '\e902';
$icon-arrow-left-path2: '\e903';
$icon-arrow-right-path1: '\e904';
$icon-arrow-right-path2: '\e905';
$icon-arrow-up-path1: '\e906';
$icon-arrow-up-path2: '\e907';
$icon-bed: '\e909';
$icon-brochure: '\e90a';
$icon-budget: '\e90b';
$icon-calendar: '\e90c';
$icon-camp-fire: '\e90d';
$icon-caret-down: '\e90f';
$icon-caret-right: '\e910';
$icon-check: '\e911';
$icon-close: '\e912';
$icon-combo: '\e913';
$icon-cook-fire: '\e914';
$icon-email: '\e915';
$icon-filter: '\e917';
$icon-heart: '\e918';
$icon-hotel: '\e91a';
$icon-house: '\e91b';
$icon-internet: '\e91c';
$icon-location: '\e91d';
$icon-map: '\e91e';
$icon-meal: '\e91f';
$icon-negative: '\e920';
$icon-options: '\e921';
$icon-people: '\e922';
$icon-phone: '\e923';
$icon-plus: '\e924';
$icon-positive: '\e925';
$icon-recognition: '\e926';
$icon-rooms: '\e927';
$icon-search: '\e929';
$icon-share: '\e92a';
$icon-star: '\e92c';
$icon-stove: '\e92d';
$icon-tent: '\e92e';
$icon-file: '\e92e';

@font-face {
    font-family: '#{$icomoon-font-family}';
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?9jvmw4');
    src: url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?9jvmw4#iefix')
            format('embedded-opentype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?9jvmw4')
            format('truetype'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?9jvmw4')
            format('woff'),
        url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?9jvmw4##{$icomoon-font-family}')
            format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

.svg-icon {
    width: 20px;
    height: 20px;
    fill: $santas-gray;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: '#{$icomoon-font-family}' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.big-blue {
    font-size: 16px;
    color: $blue-jeans;
}

.icon-20 {
    font-size: 20px;
}

.icon-voucher {
    &:before {
        content: $icon-voucher;
    }
}

.icon-cancel {
    &:before {
        content: $icon-cancel;
    }
}

.icon-pen {
    &:before {
        content: $icon-pen;
    }
}

.icon-sleeping-bag {
    &:before {
        content: $icon-sleeping-bag;
    }
}

.icon-night {
    &:before {
        content: $icon-night;
    }
}

.icon-house-tent {
    &:before {
        content: $icon-house-tent;
    }
}

.icon-bell {
    &:before {
        content: $icon-bell;
    }
}
.icon-exclamation {
    &:before {
        content: $icon-exclamation;
    }
}

.icon-user {
    &:before {
        content: $icon-user;
    }
}
.icon-cog {
    &:before {
        content: $icon-cog;
    }
}
.icon-dots {
    &:before {
        content: $icon-dots;
    }
}
.icon-transaction {
    &:before {
        content: $icon-transaction;
    }
}
.icon-star-outline {
    &:before {
        content: $icon-star-outline;
    }
}

.icon-arrow-down .path1 {
    &:before {
        content: $icon-arrow-down-path1;
        color: rgb(153, 155, 170);
        opacity: 0.64;
    }
}
.icon-arrow-down .path2 {
    &:before {
        content: $icon-arrow-down-path2;
        margin-left: -1.5em;
        color: rgb(26, 30, 64);
    }
}
.icon-arrow-left {
    position: relative;
    padding-right: 3px; //adjustment for the absolute setting for path1

    .path1 {
        position: absolute;
        left: 3px;
        top: 0;

        &:before {
            content: $icon-arrow-left-path1;
            color: rgb(153, 155, 170);
            opacity: 0.64;
        }
    }
    .path2 {
        &:before {
            content: $icon-arrow-left-path2;
            color: rgb(26, 30, 64);
        }
    }
}


.icon-arrow-right {
    position: relative;
    padding-left: 10px; //adjustment for the absolute setting for path1
    top: 0;

    .path1 {
        position: absolute;
        left: 0;

        &:before {
            content: $icon-arrow-right-path1;
            color: rgb(153, 155, 170);
            opacity: 0.64;
        }
    }

    .path2 {
        margin-left: -.5em;
        &:before {
            content: $icon-arrow-right-path2;
            color: rgb(26, 30, 64);
        }
    }

    &.primary {
        .path1 {
            &:before {
                content: $icon-arrow-right-path1;
                color: $blue-jeans;
                opacity: 0.64;
            }
        }

        .path2 {
            &:before {
                content: $icon-arrow-right-path2;
                color: $blue-jeans;
            }
        }
    }

    &.white {
        .path1 {
            &:before {
                content: $icon-arrow-right-path1;
                color: $just-white;
                opacity: 0.64;
            }
        }

        .path2 {
            &:before {
                content: $icon-arrow-right-path2;
                color: $just-white;
            }
        }
    }
}

.icon-arrow-up .path1 {
    &:before {
        content: $icon-arrow-up-path1;
        color: rgb(153, 155, 170);
        opacity: 0.64;
    }
}
.icon-arrow-up .path2 {
    &:before {
        content: $icon-arrow-up-path2;
        margin-left: -1.5em;
        color: rgb(26, 30, 64);
    }
}
.icon-backpack {
    &:before {
        content: $icon-backpack;
    }
}
.icon-bed {
    &:before {
        content: $icon-bed;
    }
}
.icon-brochure {
    &:before {
        content: $icon-brochure;
    }
}
.icon-budget {
    &:before {
        content: $icon-budget;
    }
}
.icon-calendar {
    &:before {
        content: $icon-calendar;
    }
}
.icon-camp-fire {
    &:before {
        content: $icon-camp-fire;
    }
}
.icon-camp-ground {
    &:before {
        content: $icon-camp-ground;
    }
}
.icon-caret-down {
    &:before {
        content: $icon-caret-down;
    }
}
.icon-caret-right {
    &:before {
        content: $icon-caret-right;
    }
}
.icon-check {
    &:before {
        content: $icon-check;
    }
}
.icon-close {
    &:before {
        content: $icon-close;
    }
}
.icon-combo {
    &:before {
        content: $icon-combo;
    }
}
.icon-cook-fire {
    &:before {
        content: $icon-cook-fire;
    }
}
.icon-email {
    &:before {
        content: $icon-email;
    }
}
.icon-family-walk {
    &:before {
        content: $icon-family-walk;
    }
}
.icon-filter {
    &:before {
        content: $icon-filter;
    }
}
.icon-heart {
    &:before {
        content: $icon-heart;
    }
}
.icon-hike {
    &:before {
        content: $icon-hike;
    }
}
.icon-hotel {
    &:before {
        content: $icon-hotel;
    }
}
.icon-house {
    &:before {
        content: $icon-house;
    }
}
.icon-internet {
    &:before {
        content: $icon-internet;
    }
}
.icon-location {
    &:before {
        content: $icon-location;
    }
}
.icon-map {
    &:before {
        content: $icon-map;
    }
}
.icon-meal {
    &:before {
        content: $icon-meal;
    }
}
.icon-negative {
    &:before {
        content: $icon-negative;
    }
}
.icon-options {
    &:before {
        content: $icon-options;
    }
}
.icon-people {
    &:before {
        content: $icon-people;
    }
}
.icon-phone {
    &:before {
        content: $icon-phone;
    }
}
.icon-plus {
    &:before {
        content: $icon-plus;
    }
}
.icon-positive {
    &:before {
        content: $icon-positive;
    }
}
.icon-recognition {
    &:before {
        content: $icon-recognition;
    }
}
.icon-rooms {
    &:before {
        content: $icon-rooms;
    }
}
.icon-search-house {
    &:before {
        content: $icon-search-house;
    }
}
.icon-search {
    &:before {
        content: $icon-search;
    }
}
.icon-share {
    &:before {
        content: $icon-share;
    }
}
.icon-sign-tent {
    &:before {
        content: $icon-sign-tent;
    }
}
.icon-star {
    &:before {
        content: $icon-star;
    }
}
.icon-stove {
    &:before {
        content: $icon-stove;
    }
}
.icon-tent {
    &:before {
        content: $icon-tent;
    }
}
.icon-file {
    &:before {
        content: $icon-file;
    }
}
