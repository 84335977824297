.booking-date-widget {
  .range-display-component {
    border: 1px solid $space-cadet-25;
    border-radius: 16px;
    background-color: $just-white;
    display: inline-flex;
    align-items: center;
    position: relative;

    flex-direction: column;
    max-height: 100%;
    width: 100%;
    padding: 8px;

    .boxed-icon {
      display: none;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      padding: 8px 45px 8px 8px;
      max-height: 48px;
      width: auto;

      .boxed-icon {
        display: flex;
      }
    }

    .start-date, .end-date {
      width: 175px;
      padding: 0 16px;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      cursor: pointer;
    }

    .start-date {
      color: $text-color;
    }

    .end-date {
      color: $space-cadet-50;

      &.active {
        color: $text-color;
      }
    }

    svg {
      height: 20px;
      fill: $space-cadet-50;
    }

    .btn-clear {
      cursor: pointer;
      position: absolute;
      right: 0;
      padding: 16px;
      display: flex;

      &.hidden {
        display: none;
      }
    }
  }

  .modal-component {
    &.hidden {
      display: none;
    }
  }

  input {
    border: none;
    text-align: center;
  }

  .selected-content {
    color: $just-white !important;
  }

  .vc-day-box-center-center {
    .selected.vc-highlight {
      border-radius: 10px !important;
      background-color: $mantis !important;
      border-color: $mantis !important;
    }

    .selected.vc-highlight-base-middle {
      border-radius: 10px;
      background-color: $mantis;
      width: 28px;
    }
  }

  .vc-day-content {
    font-weight: bold !important;

    &:focus {
      background-color: transparent !important;
    }

    &:hover {
      border-radius: 10px !important;
    }

    &.is-disabled {
      text-decoration: line-through;
      color: #cbd5e0;
      opacity: .4;
    }

    &.booking-start-date {
      text-decoration: none !important;
      font-weight: bold;
    }

    &.booking-slot-date {
      color: rgb(229, 230, 233)!important;
    }
  }

  .vc-day-box-right-center, .vc-day-box-left-center {
    display: none;
  }

  .vc-day-popover-header {
    display: none;
  }

  .vc-day-box-center-bottom {
    .vc-bars {
      .vc-bar {
        &:nth-child(2) {
          margin: 0 1px;
        }
      }
    }
  }


  .multiselect {
    border: 1px solid rgb(229,230,233);
    border-radius: 16px;
    height: 48px;
    box-shadow: none;

    .multiselect-clear {
      display: none;
    }

    .multiselect-dropdown {
      bottom: -5px;
      border-radius: 16px;

      .multiselect-options {
        padding: 4px;
      }

      .multiselect-option {
        margin-bottom: 2px;
      }

      .multiselect-option.is-selected {
        background-color: $mantis;
        color: $just-white;
        border-radius: 12px;
      }

      .multiselect-option.is-pointed {
        background-color: $mantis-10;
        color: $mantis;
        border-radius: 12px;
      }
    }
  }
}