.accommodation-search-widget {

  .no-results {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: bold;
    font-size: 21px;
    color: $athens-gray;
    margin-top: 3em;
  }

  .accommodation-sorting {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    .small-select {
      min-width: 100%;
      margin-top: 1em;

      @include media-breakpoint-up(lg) {
        margin-top: 0;
        min-width: 200px;
      }
    }
  }

  .btn-toggle-mobile-facets {
    height: 56px;
    line-height: 56px;
    background-color: $space-cadet-75;
    color: $just-white;
    font-weight: bold;
    border-radius: 16px;
    border: 0;
    padding: 0 24px;
    width: 100%;
    margin-bottom: 1em;
    cursor: pointer;

    i {
      margin-right: .8em;
    }
  }

  .accommodation-map {
    border-radius: 24px;
    overflow: hidden;


    .H_ib_body {
      bottom: 0.8em;
      margin-right: -14.3em;
      box-shadow: none;
      border-radius: 18px;

      .H_ib_content {
        min-width: 12em;
        filter: drop-shadow(0px 8px 48px rgba(26, 30, 64, 0.12));
        border-radius: 18px;
        margin: 0;
        overflow: hidden;

        .map-domain-teaser {
          img {
            max-width: 300px;
          }

          a {
            text-decoration: none;
          }

          .teaser-content {
            padding: 1.5em;
            margin-top: -7px;

            h6 {
              font-size: 20px;
            }
          }

          .map-domain-accommodation-list-teaser {
            .map-domain-accommodations-teaser {
              display: flex;
              justify-content: space-between;
              color: #9293A3;
              text-transform: uppercase;
              margin-top: .5em;

              .icon-share {
                transform: rotate(90deg);
              }

              .availability-numbers {
                font-size: 14px;
                color: #1A1E40;
                font-family: 'Bowlby One';



                .text-green {
                  color: $mantis;
                }

                span {
                  color: #E5E6E9;
                  font-size: 20px;
                }
              }

              .boxed-icon {
                width: 20px;
                height: 20px;
                border-radius: 5px;

                i {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }

      .H_ib_close {
        font-size: .6em;
        position: absolute;
        right: calc(50% - 30px);
        bottom: -35px;
        top: auto;
        width: 20px;
        height: 20px;
        padding: 15px;
        border-radius: 50%;
        cursor: pointer;
        background-color: rgb(255,131,96);
        z-index: 1;
        box-shadow: none;

        svg {
          fill: white;
        }
      }
    }

    .H_ib_tail {
      display: none;
    }
  }

  .accommodation-facet-list {
    position: fixed;
    top: 0;
    left: -100%;
    background-color: $space-cadet-10;
    padding: 42px 24px 160px 24px;
    width: 100%;
    height: 100vh;
    z-index: 90;
    transition: left .125s ease-in;
    overflow-y: auto;

    @-moz-document url-prefix() {
      top: 64px;
    }

    @include media-breakpoint-up(lg) {
      position: relative;
      background-color: transparent;
      padding: 0;
      top: 0;
      left: 0;
      height: auto;
      z-index: auto;
      overflow-y: unset;
    }

    &.mobile-view-active {
      left: 0;
    }

    .icon-close {
      color: $blue-jeans;
      cursor: pointer;
    }
  }
}