footer {
  background-color: $lavender-purple-10;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  padding: 30px 30px 130px 30px;
  margin-top: 8em;

  @include media-breakpoint-up(xl) {
    padding: 30px 0 0 0;
  }

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    li {
      padding: 8px 16px;

      a {
        color: $lavender-purple;
        text-decoration: none;
        transition: all .125s ease-in;
        display: block;

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }

  .powered-by {
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-family: 'Raleway';
    color: $lavender-purple-50;
    margin: 15px 0;
    font-size: 12px;
    text-decoration: none;

    span {
      font-family: 'Bowlby One', sans-serif;
    }
  }

  .social-media {
    display: flex;
    justify-content: center;
    margin-top: 1em;

    a {
      margin: 0 8px;
      color: $lavender-purple;
      transition: all .125s ease-in;

      &:hover {
        transform: scale(1.05);
      }
    }
  }
}