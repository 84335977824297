table {
    width: 100%;

    thead {
        tr {
            th {
                padding-bottom: 16px;
                padding-right: 16px;
                font-weight: bold;
                color: $santas-gray;
                text-transform: uppercase;
                text-align: left;
            }
        }
    }

    tbody {
        tr {
            border-top: 1px solid $athens-gray-10;

            &.border-less {
                border-top: 0;
            }

            td {
                padding: 16px 0;

                b {
                    font-size: 12px;
                    text-transform: uppercase;
                    color: $text-color-alt;
                    max-width: calc(100% - 56px); // enforce minimal space for the icon

                    @include media-breakpoint-up(md) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
