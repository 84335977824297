.breadcrumbs-wrapper {
  display: flex;
  align-items: center;

  .go-back-link {
    text-decoration: none;
    display: flex;
    margin-right: 1em;
  }

  .breadcrumbs {
    display: flex;
    align-items: center;

    li {
      &:after {
        content: '/';
        display: inline-block;
        font-weight: normal;
        margin: 0 4px;
        font-size: 10px;
        color: $mischka;

        @include media-breakpoint-up(md) {
          font-size: 14px;
        }

        @include media-breakpoint-up(xl) {
          font-size: 16px;
        }
      }

      &:last-of-type {
        &:after {
          display: none;
        }
      }
    }

    a, span {
      color: $santas-gray;
      font-size: 10px;

      @include media-breakpoint-up(md) {
        font-size: 14px;
      }

      @include media-breakpoint-up(xl) {
        font-size: 16px;
      }

    }

    a {
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: $blue-jeans;
      }
    }

    span {
      font-weight: normal;
      user-select: none;
    }
  }
}