.text-image-block {

  .text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    p {
      font-size: 18px;
    }

    .block-link {
      text-decoration: none;
      color: $blue-jeans;
      font-size: 16px;
      line-height: 16px;
      display: flex;
      align-content: center;

      i {
        margin-left: 1em;
      }
    }
  }
}