.form {
  .form-group {
    margin-bottom: 1em;

    label {
      display: block;
      font-size: 12px;
      font-weight: bold;
      line-height: 12px;
      color: $santas-gray;
      margin-bottom: 8px;

      a {
        font-size: 12px;
      }
    }

    input, select {
      width: 100%;
    }
  }
}