html {
  scroll-behavior: smooth;
}

body {
  &.no-scrolling {
    position: fixed!important
  }
}

.container {
  max-width: 1240px !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.print-layout {
  display: none;
}

@media print {
  .custom-print {
    main, footer {
      display: none;
    }

    .print-layout {
      display: block;
    }

    .print-tables-on-separate-page {
      width: 210mm;
      tbody {
        display: block;
        page-break-after: always;
        page-break-inside: avoid;
        page-break-before: avoid;
      }
    }
  }
}

.background-render {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

body {
  padding: 64px 0 0 0;
  overflow-x: hidden;
  display:grid; // Line 1
  grid-template-rows: auto 1fr auto; // Line 2
  min-height:100vh;

  main {
    width: 100vw;
  }
}

.hidden {
  display: none;
}

.rotate-180 {
  transform: rotate3d(0,0,1,180deg);
}

.cursor-pointer {
  cursor: pointer;
}

.block-spacing {
  margin-bottom: 64px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 120px;
  }
}

.alert-danger {
  color: $wild-watermelon;
}

.text-orange {
  color: $coral;
}

.text-green {
  color: $mantis;
}

.text-blue {
  color: $blue-jeans;
}

.text-yellow {
  color: $naples-yellow-125;
}

.text-grey-light {
  color: $athens-gray;
}

.text-grey {
  color: $space-cadet-50;
}

.w-md-80 {
  @include media-breakpoint-up(md) {
    width: 80% !important;
  }
}

.w-lg-60 {
  @include media-breakpoint-up(lg) {
    width: 60% !important;
  }
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  td {
    position: relative;
  }
}

.fw-bolder {
  font-weight: 600 !important;
}

.text-decoration-none {
  text-decoration: none;
}

.fw-normal {
  font-weight: normal;
}

.white-space-nowrap {
  white-space: nowrap;
}

.text-gray-600 {
  color: rgb(126,130,153) !important;
}

small, .small {
  font-size: 0.875em;
}

.svg-icon {
  display: inline-flex;
}

.svg-icon.svg-icon-2hx svg {
  height: 2.5rem !important;
  width: 2.5rem !important;
}

.svg-icon.svg-icon-primary svg [fill]:not(.permanent):not(g) {
  transition: fill 0.3s ease;
  fill: rgb(0, 158, 247);
}

.svg-icon.svg-icon-primary svg [fill]:not(.permanent):not(g) {
   transition: fill 0.3s ease;
   fill: rgb(0, 158, 247);
 }

hr {
  border: none;
  border-top: 1px solid $space-cadet-25;

  &.dashed {
    border-top-style: dashed;
  }
}

.flex-stack {
  justify-content: space-between;
}

.border-0 {
  border: none;
}

.grecaptcha-badge { opacity:0;}

