.search-accommodation-component {
  border-radius: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  height: 515px;
  width: 100%;
  background-color: $just-white;
  filter: drop-shadow(0px 8px 48px rgba(26, 30, 64, 0.12));
  will-change: filter;
  position: relative;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255, .5);
    display: flex;
  }

  .img-wrapper {
    width: 100%;
    max-height: 200px;
    min-height: 200px;

    @include media-breakpoint-up(md) {
      width: 40%;
      max-height: none;
    }

    picture {
      display: block;
      height: 100%;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .content  {
    padding: 24px;
    width: 100%;
    min-height: 315px;
    display: flex;
    flex-direction: column;
    position: relative;
    padding-right: calc(70px + 24px);

    @include media-breakpoint-up(md) {
      width: 60%;
      padding-right: 24px;
    }

    .listings {
      flex-grow: 1;
    }

    a {
      text-decoration: none;

      h4 {
        font-size: 18px;

        @include media-breakpoint-up(md) {
          font-size: 24px;
        }
      }

      &:hover {
        h4 {
          color: $blue-jeans;
        }
      }
    }

    h4 {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .description {
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: -webkit-box;
      margin-bottom: 1em;
      max-height: 128px;

      p, a {
        font-size: 14px;
        line-height: 22px;

        @include media-breakpoint-up(md) {
          font-size: 16px;
          padding-right: 32px;
        }
      }
    }

    .listing {
      border-top: 1px solid $space-cadet-10;
      padding: .8em;
      font-weight: normal;
      height:  calc(85px + 1.6em);
      display: block;

      @include media-breakpoint-up(md) {
        height: calc(60px + 1.6em);
      }

      &:hover {
        .name {
          color: $blue-jeans;
        }

        .icon-arrow-right {
          opacity: .5;
        }
      }

      .icon-arrow-right {
        opacity: 0;
        transition: all ease-in-out .125s;
      }

      .icon-combo {
        color: $coral;
      }

      .name {
        font-size: 0.9rem;
        font-weight: bold;
        color: $text-color;
      }

      .stats-wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }

        .stats-group {
          display: flex;
        }

        .stat {
          color: $space-cadet-50;
          display: flex;
          align-items: center;

          font-size: 14px;

          @include media-breakpoint-up(lg) {
            font-size: 12px;
          }

          @include media-breakpoint-up(xl) {
            font-size: 14px;
          }

          &:before {
            content: '●';
            display: inline-block;
            margin: 0 6px;
            color: $blue-jeans-25;
            font-size: 10px;
          }

          &:first-of-type {
            &:before {
              content: none;
            }
          }

          i {
            color: $blue-jeans;
            margin-right: 6px;
          }

          .pricetag {
            display: flex;
            flex-direction: column;
            align-items: center;
            line-height: 16px;
          }
        }
      }
    }

    .mobile-listing-panel {
      display: flex;
      position: absolute;
      top: 0;
      left: calc(100% - 85px);
      width: 100%;
      height: 100%;
      transition: all ease-in .225s;
      background-color: $just-white;

      &.is-active {
        left: 10px;

        .mobile-listing-panel-trigger {
          .icon-close {
            animation: .5s ease-out .27s 1 stop forwards;
          }
        }

        .mobile-listing-panel-content {
          .listings {
            opacity: 1;
          }
        }
      }

      .mobile-listing-panel-content {
        width: calc(100% - (30px + 10px));
        height: 100%;

        .listings {
          opacity: .5;
          margin-left: 8px;
          height: 100%;
        }
      }

      .mobile-listing-panel-trigger {
        width: 30px;
        height: 100%;
        border: none;
        background-color: $just-white;

        i {
          color: $text-color;
          display: block;

          &.coi {
            &:after {
              outline: 1px solid $aqua-haze;
            }
          }
        }
      }
    }
  }

  .show-accommodation-on-map {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: absolute;
    top: 24px;
    left: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $just-white;
    text-decoration: none;
    transition: transform .125s ease-in;

    i {
      color: $coral;
      font-size: 21px;
    }

    &:hover {
      transform: scale3d(1.125, 1.125, 1.125);
    }
  }
}

.small-select {
  .multiselect {
    height: 40px;
    border-radius: 8px;
    padding: 0 8px;
    font-size: 14px;
  }
}
