.reservation-success-page {
  .booking-mini {
    i {
      color: $blue-jeans;
    }
  }


  .reservation-success {
    position: relative;
    background-color: $mantis-10;
    padding-bottom: 25vw;
    z-index: 5;

    &:after {
      content: ' ';
      display: block;
      background-color: $just-white;
      width: 100%;
      height: 25vw;
      border-top-left-radius: 100%;
      border-top-right-radius: 100%;
      position: absolute;
      bottom: -5%;
      transition: transform ease-out .5s;
      animation: slideUp 1s ease-out .5s 1 forwards;
    }

    h2 {
      font-size: 24px;
      line-height: 32px;

      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
        line-height: 48px;
      }
    }
  }

  .reservation-summary {
    margin-top: -10vw;
    z-index: 10;
    position: relative;

    @include media-breakpoint-up(lg) {
      margin-top: -15vw;
    }

    h5 {
      font-size: 16px;
      line-height: 24px;

      @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    .js_scroll-to-reservation-detail {
      position: absolute;
      top: -5vh;
      text-decoration: none;

      i {
        font-size: 30px;
      }
    }
  }
}
