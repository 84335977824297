header {
    background-color: $header-background;
    color: $header-color;
    display: flex;
    align-items: center;
    box-shadow: 0 16px 48px rgba(26, 30, 64, 0.12);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;

    .top-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: $header-height;
        margin: 8px 0;
        z-index: 10;

        .search {
            input {
                width: 250px;
            }
        }

        .button-group {
            padding: 0 -0.5em;

            .btn {
                line-height: calc(#{$header-height} - 10px);
            }
        }
    }

    hr {
        border-color: $space-cadet-25;
        border-top: 0;
    }

    .top-wrapper,
    .filter-wrapper {
        position: relative;

        .button-group {
            @media (min-width: 915px) {
                display: flex !important;
            }
        }
    }

    .language-switch {
        @media (max-width: 915px) {
            display: flex;
            justify-content: center;
            align-items: center;
            column-gap: 10px;
        }

        .language-button {
            position: relative;
            column-gap: 8px;

            span {
                min-width: 28px;
                text-align: center;
            }

            i {
                font-size: 14px;
            }

            .language-dropdown-ctn {
                pointer-events: none;
                opacity: 0;
                transition: opacity 0.3s;
                position: absolute;
                top: 48px;
                left: 0;
                min-width: 98px;
                padding-top: 5px;
                color: $space-cadet-75;

                .language-dropdown {
                    display: flex;
                    flex-direction: column;
                    background-color: $just-white;
                    border-radius: 5px;
                    box-shadow: 0 16px 48px rgba(26, 30, 64, 0.12);
                    overflow: hidden;
                    text-align: center;

                    a {
                        padding: 10px 15px;
                        color: $space-cadet-75;
                        text-decoration: none;

                        &:hover {
                            color: $blue-jeans
                        }
                    }
                }
            }

            &:hover {
                .language-dropdown-ctn {
                    pointer-events: all;
                    opacity: 1;
                }
            }
        }
    }

    .filter-component {
        height: $header-height;
        display: flex;
        margin: 12px 0;
        padding: 0 20px;

        .mb-3 {
            margin-bottom: 0 !important;
        }
    }
}

.flex-scale {
    flex: 1;
}

.flex-scale-2 {
    flex: 2;
}

.filter-string {
    color: $space-cadet-50;
    font-weight: bold;
    font-size: 12px;
}

.filter-element,
.filter-input {
    input {
        border-radius: 12px;
    }
}

.filter-input {
    position: relative;

    input {
        padding-left: 48px;
        width: 175px;
    }

    &.filter-daterange {
        input {
            width: 225px;
        }
    }

    .filter-icon {
        width: 32px;
        height: 32px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $just-white;
        position: absolute;
        top: 8px;
        left: 8px;

        &.blue {
            background-color: $blue-jeans;
        }

        &.green {
            background-color: $mantis;
        }
    }
}

.mobile-header-menu {
    align-items: center;

    @media (min-width: 915px) {
        display: none !important;
    }

    hr {
        margin: 20px 0;
    }

    .mobile-menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: white;
        z-index: -1;
        padding: 120px 30px 30px 30px;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
        box-shadow: 0 8px 48px rgba(26, 30, 64, 0.24);
        transition: transform 0.15s ease-in;

        .button-group {
            display: flex;
            flex-direction: column;
            opacity: 1;
            transition: opacity 0.5s ease-in 0.18s;
        }

        .btn {
            width: 100%;

            &.btn-link {
                border: 1px solid $blue-jeans-50;
            }
        }

        .user-menu-list {
            .user-menu-link {
                margin-bottom: 20px;
                padding: 8px;
            }
        }

        &.hidden {
            display: block;
            transform: translateY(-100%);
            box-shadow: none;

            .button-group {
                opacity: 0;
            }
        }
    }
}

.home-page {
    .header-bg {
        background-color: transparent;
        box-shadow: none;
    }

    .filter-wrapper {
        display: none;
    }

    .mobile-filter-wrapper {
        display: flex;

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
}

.filter-wrapper,
.mobile-filter-wrapper {
    display: none;
}

.header-with-search {
    padding-top: 48px;

    @include media-breakpoint-up(xl) {
        padding-top: 137px;
    }

    .filter-wrapper {
        display: none;

        @include media-breakpoint-up(xl) {
            display: block;
        }
    }

    .mobile-filter-wrapper {
        display: flex;

        @include media-breakpoint-up(xl) {
            display: none;
        }
    }
}

.type-filter {
    .ts-control {
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap;
        width: 100px;
    }
}
