.badge {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  border-radius: 16px;
  height: 24px;
  color: $just-white;
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: bold;
  font-size: 12px;

  &.green {
    background-color: $mantis-10;
    color: $mantis;
  }

  &.blue {
    background-color: $blue-jeans-10;
    color: $blue-jeans;
  }

  &.red {
    background-color: $wild-watermelon-10;
    color: $wild-watermelon;
  }

  &.orange {
    background-color: $coral-10;
    color: $coral;
  }

  &.yellow {
    background-color: $naples-yellow-10;
    color: $naples-yellow-125;
  }

  &.black {
    background-color: $text-color;
    color: $just-white;
  }

  &.grey {
    background-color: $athens-gray;
    color: $text-color;
  }
}