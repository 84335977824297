.pre-auth {
    background-color: $just-white;

    .background-image {
        height: 100vh;
        object-fit: cover;
        display: none;

        @include media-breakpoint-up(lg) {
            display: block;
            width: calc(100% - 500px);
        }
    }

    &.login-page, &.password-page, &.registration-page {
        padding: 0;
    }

    &.login-page, &.password-page {
        background-color: $mantis-10;

        .link-forgot {
            font-size: 12px;
            line-height: 32px;
        }

        .alert {
            padding: 6px;
            color: $blue-jeans-125;
            margin: 8px 0;
        }
    }

    &.registration-page {
        background-color: $blue-jeans-10;

        .invalid-feedback {
            margin-top: -15px;
            color: $wild-watermelon;
        }
    }

    small {
        color: $santas-gray;
    }

    .background-pre-auth {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        z-index: -1;
    }

    .card-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        .card {
            width: 100%;
            background-color: $just-white;
            box-shadow: 0 8px 48px rgba(26, 30, 64, 0.12);
            border-radius: 12px;
            padding: 24px;

            img {
                height: 100px;
                margin: 0 auto;
                display: block;
            }

            @include media-breakpoint-up(md) {
                padding: 48px;
                max-width: 600px;
            }
        }
    }
}
