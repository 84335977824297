.form-group {
  &.horizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;

    input, .invalid-feedback {
      width: 200px;
    }
  }
}

.form-control, .form-select {
  display: block;
  width: 100%;

  &:disabled {
    background: unset;
    opacity: 0.7;

    .form-select.form-select-solid {
      background-color: red;
    }
  }
  &:hover:disabled {
    background: unset;
  }
}

.select2-container--disabled {
  .form-select.form-select-solid {
    background-color: unset;
    cursor: unset;
  }
  .form-select {
    background-image: unset;
  }
  .select2-selection__clear {
    display: none;
  }
}

.form-without-group-margin {
  .mb-3 {
    margin-bottom: 0 !important;
  }
}

.contact-form-select-element {
  .ts-control {
    &:after {
      content: '\e90f';
      font-family: '#{$icomoon-font-family}';
      font-size: 10px;
      margin-left: 8px;
      position: absolute;
      right: 0;
    }
  }
}