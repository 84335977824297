.tabs-component {
    width: 100%;
    filter: drop-shadow(0px 8px 48px rgba(26, 30, 64, 0.12));
    position: relative;
    margin-bottom: 64px;

    @include media-breakpoint-up(xl) {
        margin-bottom: 0;
    }

    .download-link {
        position: absolute;
        bottom: -64px;
        left: 0;
        line-height: 64px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        @include media-breakpoint-up(xl) {
            width: auto;
            bottom: auto;
            left: auto;
            top: 0;
            right: 0;
        }

        i {
            font-size: 18px;
            margin-right: 13.33px;
            margin-top: -2px;
        }
    }

    .tabs-headers {
        display: flex;
        justify-content: flex-start;
        background-color: $tabs-background-color;
        border-radius: 24px 24px 0px 0px;
        height: 64px;

        @include media-breakpoint-up(xl) {
            background-color: transparent;
        }

        .tabs-header {
            color: $tabs-header-text-color;
            font-family: 'Raleway', sans-serif;
            font-style: normal;
            font-weight: bold;
            text-align: center;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 164.25px;
            height: 64px;
            padding: 0 24px;
            cursor: pointer;
            position: absolute;
            width: 100%;

            &:not(.active) {
                opacity: 0;
            }

            @include media-breakpoint-up(xl) {
                position: relative;
                width: auto;

                &:before {
                    content: ' ';
                    display: block;
                    background-color: $tabs-background-color;
                    position: absolute;
                    width: 100%;
                    height: 88px;
                    border-radius: 24px 24px 0px 0px;
                    z-index: -1;
                    bottom: -88px;
                    transition: bottom 0.125s ease-in-out;
                }

                &:not(.active) {
                    opacity: 1;

                    &:hover {
                        &:before {
                            bottom: -68px;
                        }
                    }
                }

                &.active {
                    &:before {
                        bottom: -24px;
                    }
                }
            }
        }
    }

    .control-panel {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 64px;
        display: flex;
        justify-content: space-between;
        padding: 0 24px;

        button {
            background-color: transparent;
            border: none;
            width: 30px;
        }
    }

    .tabs-content {
        width: 100%;
        background-color: $tabs-background-color;
        min-height: 375px;
        border-radius: 0 0 24px 24px;
        padding: 24px;

        @include media-breakpoint-up(xl) {
            border-radius: 24px 24px 24px 24px;
        }

        .tab-panel {
            min-height: inherit;

            &:not(.active) {
                display: none;
            }
        }
    }
}
