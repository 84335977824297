.flatpickr-inline {
  visibility: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: 0;
}

.flatpickr-calendar {
  border-radius: 12px;
  width: auto;
  padding: 8px;
  background-color: #F3F3F4;
  border: none !important;

  &:before, &:after {
    content: none;
  }

  .flatpickr-month {
    color: $space-cadet-75;
    fill: $space-cadet-75;
  }

  .flatpickr-monthDropdown-months, .cur-year {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 18px;
  }

  .flatpickr-weekday {
    color: $space-cadet-50;
    font-size: 14px;
    font-weight: bold;
  }

  .flatpickr-day {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 34.14px;
    height: 32px;
    background-color: $space-cadet-25;
    color: $space-cadet-50;
    margin-bottom: 4px;

    &:hover {
      background-color: $mantis;
      color: $just-white;
      border: $mantis;
    }

    &.flatpickr-disabled {
      &:hover {
        background-color: $space-cadet-25;
        color: $space-cadet-50;
      }
    }

    &.today {
      border: none;

      &:hover {
        background-color: $mantis;
        color: $just-white;
        border: $mantis;
      }
    }

    &.prevMonthDay, &.nextMonthDay {
      background-color: $just-white;
      color: $space-cadet-25;
    }

    &.selected, &.startRange, &.inRange, &.endRange {
      background-color: $mantis;
      color: $just-white;
      border-radius: 8px !important;
      border-color: $mantis;
      box-shadow: none !important;

      &:hover {
        background-color: $mantis;
        color: $just-white;
        border-color: $mantis;
      }
    }
  }
}