.text-counter {
  position: relative;

  &:after {
    content: attr(data-counter);
    color: $space-cadet-75;
    position: absolute;
    right: 16px;
    bottom: -18px;
    font-size: 14px;
    line-height: 14px;
  }
}
