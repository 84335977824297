$space-cadet: #1a1e40;
$space-cadet-75: #53566f;
$space-cadet-50: #999BAA;
$space-cadet-25: #E5E6E9;
$space-cadet-10: #F3F3F4;
$just-white: #ffffff;

$bootstrap-lightgreen: #e8fff3;
$mantis-125: #5d9a61;
$mantis: #7dce82;
$mantis-50: #BEE6C0;
$mantis-25: #DEF2DF;
$mantis-10: #f2faf2;

$blue-jeans: #57b8ff;
$blue-jeans-125: #418ABF;
$blue-jeans-50: #BCE0FD;
$blue-jeans-25: #D5EDFF;
$blue-jeans-10: #e0f2ff;
$blue-jeans-10-2: #EEF7FF;
$bermuda: #81CEDD;
$endeavour: #005BA8;

$coral: #ff8360;
$coral-10: #ffeee9;
$coral-25: #FFE0D7;
$coral-75: #FFA287;
$coral-125: #BF6248;


$wild-watermelon-125: #8d3e3e;
$wild-watermelon: #ff606a;
$wild-watermelon-10: #ffe9eb;
$sea-pink: #EA979F;

$lavender-purple: #8C76B2;
$lavender-purple-50: #b4a8ca;
$lavender-purple-10: #f0ecf7;

$copper: #b78b3a;
$copper-125: #63491f;

$bootstrap-lightyellow: #fff8dd;
$naples-yellow-125: #f6cc16;
$naples-yellow: #fcedad;
$naples-yellow-10: #fef9e6;
$naples-yellow-25: #FDF6D6;
$naples-yellow-50: #FCEDAD;
$naples-yellow-75: #FAE484;
$salomie: #FCDD81;

$athens-gray: #e5e6e9;
  $athens-gray-10: #f3f3f4;
$santas-gray: #999baa;
$aqua-haze: #F6F8FA;
$mischka: #D7DBE2;

$form-element-background: #f5f8fa;
$text-color: $space-cadet;
$text-color-alt: $space-cadet-75;
$link-color: $blue-jeans;
$background: #EDF7FF;
$manager-portal-lightgray: $aqua-haze;
$manager-portal-gray: $mischka;

// COMPONENTS

// TAB COMPONENT
$tabs-background-color: $just-white;
$tabs-header-text-color: $mantis-125;
