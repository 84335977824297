.document-mini {
  display: flex;
  align-items: center;
  width: 100%;
  color: $text-color;
  text-decoration: none;
  border-top: 1px solid $athens-gray;
  padding: 8px 0;

  &:first-of-type {
    border-top: none;
  }

  i {
    margin-right: 1em;
  }

  &:hover {
    color: $blue-jeans;
  }
}