.calendar-component {
    box-shadow: 0px 8px 48px rgba(26, 30, 64, 0.12);
    border-radius: 24px;
    background-color: $just-white;
    padding: 16px 32px;
    max-width: 460px;

    .legend {
        margin: 16px -16px 0;
        padding: 12px;
        border: 1px solid $athens-gray;
        border-radius: 16px;


        @media (min-width: 1200px) {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-template-areas:
            ". . ."
            "merged merged merged";
        }

        .merged { grid-area: merged; }

        .legend-item {
            display: flex;
            align-items: center;
            font-weight: bold;
            color: $space-cadet-75;
            height: 32px;
            font-family: Arial, sans-serif;

            .legend-color {
                width: 24px;
                height: 24px;
                border-radius: 8px;
                margin-right: 16px;
                display: block;

                @media (min-width: 1200px) {
                    margin-right: 10px;
                }

                &.open {
                    background-color: $just-white;
                    border: 1px solid $athens-gray;
                }

                &.full {
                    background-color: $wild-watermelon;
                    border: 1px solid $wild-watermelon;
                }

                &.in-option {
                    background-color: $naples-yellow;
                    border: 1px solid $naples-yellow;

                }

                &.closed {
                    background-color: $athens-gray;
                    border: 1px solid $athens-gray;
                }

                &.partially-booked {
                    background-color: $coral-75;
                    border: 1px solid $coral-75;
                }
            }
        }
    }
}

// OVERWRITES
.vc-container,
.vc-popover-content-wrapper,
.vc-pane-layout {
    width: 100% !important;
}
.vc-container {
    border: none !important;
}

.vc-container [role='button'],
.vc-container button {
    cursor: unset !important;
}

.vc-header {
    margin-bottom: 20px !important;

    .vc-title {
        color: $space-cadet-75 !important;
    }
}

.vc-day-content:focus {
    background-color: transparent !important;
}

.vc-day.in-prev-month, .vc-day.in-next-month {
    opacity: .4;
}

.vc-day.is-not-in-month * {
    color: $athens-gray !important;
    opacity: 1 !important;
}


    .vc-day-content {
        border-radius: 8px !important;
        width: 100% !important;
        margin: 0 2px !important;
        background-color: transparent !important;

        &:before, &:after {
            z-index: -1;
        }

        &.full {
            background-color: $wild-watermelon !important;
        }

        &.open {
            background-color: transparent !important;
        }

        &.in-option {
            background-color: $naples-yellow !important;
        }

        &.closed {
            //background-color: $athens-gray !important;
            background-color: $wild-watermelon !important;
        }

        &.partially-booked {
            background-color: $coral-75 !important;
        }

        &.open-full, &.full-start {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $just-white;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.open-closed, &.closed-start {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $just-white;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                //background-color: $athens-gray;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.open-in-option, &.in-option-start {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $just-white;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $naples-yellow;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.open-partially-booked, &.partially-booked-start {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $just-white;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $coral-75;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.full-open, &.full-end {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $just-white;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.full-closed {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                //background-color: $athens-gray;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.full-in-option {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $naples-yellow;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.full-partially-booked {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $coral-75;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.in-option-open, &.in-option-end {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $naples-yellow;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $just-white;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.in-option-closed {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $naples-yellow;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                //background-color: $athens-gray;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.in-option-full {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $naples-yellow;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.in-option-partially-booked {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $naples-yellow;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $coral-75;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.closed-open, &.closed-end {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                //background-color: $athens-gray;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $just-white;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.closed-in-option {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                //background-color: $athens-gray;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $naples-yellow;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.closed-full {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                //background-color: $athens-gray;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.closed-partially-booked {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                //background-color: $athens-gray;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $coral-75;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.partially-booked-open, &.partially-booked-end {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $coral-75;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $just-white;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.partially-booked-in-option {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $coral-75;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $naples-yellow;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.partially-booked-full {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $coral-75;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }

        &.partially-booked-closed {
            background-color: transparent !important;
            position: relative;
            overflow: hidden;

            &:before {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: -12px;
                left: -33%;
                background-color: $coral-75;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }

            &:after {
                content: ' ';
                display: block;
                height: 28px;
                width: 150%;
                position: absolute;
                top: 10px;
                left: 0;
                //background-color: $athens-gray;
                background-color: $wild-watermelon;
                transform: rotate(-40deg);

                @media (min-width: 1200px) {
                    transform: rotate(-35deg);
                }
            }
        }
    }


.vc-day-content {
    color: $santas-gray !important;

    //-webkit-text-fill-color: white; /* Will override color (regardless of order) */
    //-webkit-text-stroke-width: 1px;
    //-webkit-text-stroke-color: black;

    &.full, &.full-end, &.full-start, &.full-in-option, &.in-option-full, &.full-closed, &.closed-full, &.full-partially-booked, &.partially-booked-full {
        color: $wild-watermelon-125 !important;
    }

    &.in-option, &.in-option-start, &.in-option-end {
        color: $copper !important;
    }

    &.closed {
        //color: $santas-gray !important;
        color: $wild-watermelon-125 !important;
    }

    &.partially-booked {
        color: $coral-125 !important;
    }

    &.partially-booked, &.partially-booked-end, &.partially-booked-start, &.partially-booked-in-option, &.in-option-partially-booked, &.partially-booked-closed, &.closed-partially-booked {
        color: $coral-125 !important;
    }

}