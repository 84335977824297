#accommodationPricesWidget {
  p {
    color: $space-cadet-50;
  }

  .empty-state-message {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    min-height: 375px;
    font-size: 20px;
    color: $space-cadet-75;
  }

  .tabs-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .mobile-meal-type-header {
    text-align: center !important;
    font-weight: bold;
    text-transform: uppercase;
    color: $space-cadet;
  }

  table {
    margin-top: 20px;

    &:first-of-type {
      margin-top: 0;
    }

    th {
      font-size: 16px;
      text-transform: uppercase;
      color: $space-cadet-75;
      font-weight: bold;
      text-align: right;

      &:first-of-type {
        text-align: left;
      }

      h5 {
        font-size: 24px;
      }

      span {
        color: $space-cadet-50;
        font-weight: 200;
      }
    }

    td {
      text-align: right;
      padding: 16px 0;
      border-bottom: 1px solid $space-cadet-25;
      color: $santas-gray;

      &:first-of-type {
        text-align: left;
      }

      b {
        color: $space-cadet-75;
      }
    }
  }
}