.mobile-filter-wrapper {
  position: fixed;
  bottom: 10px;
  width: 100%;
  justify-content: center;
  z-index: 85;

  @include media-breakpoint-up(xl) {
    display: none;
  }

  .mobile-filter {
    max-width: 350px;
    width: 90%;
    background-color: $just-white;
    box-shadow: 0 8px 48px rgba(26, 30, 64, 0.24);
    border-radius: 24px;
    padding: 12px;
  }

  .hidden {
    display: none !important;
  }

  .mobile-filter-trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    span {
      padding-left: 16px;
    }

    .button {
      border-radius: 16px;
      background-color: $coral;
      color: $just-white;
      height: 56px;
      width: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .mobile-filter-content {
    button {
      width: 100%;
      height: 56px;
      border-radius: 16px;
    }

    .filter-string {
      width: 100%;
      text-align: center;
      display: block;
    }

    .filter-input {
      input {
        height: 56px;
        width: 100%;
        font-size: 16px;
      }

      .filter-icon {
        top: 12px;
      }
    }

    .frame-title {
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      width: 100%;
      display: block;
    }

    .option-list-wrapper {
      position: relative;
      margin-bottom: 8px;

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        pointer-events: none;
        background-image: linear-gradient(to bottom,
                rgba(255,255,255, 0),
                rgba(255,255,255, 1) 90%);
        width: 100%;
        height: 1em;
      }
    }
    .option-list {
      max-height: 300px;
      overflow-y: auto;
    }

    .mobile-filter-option {
      height: 56px;
      width: 100%;
      font-size: 16px;
      border-radius: 16px;
      background-color: #f7f7f7;
      color: $space-cadet;
      border: 1px solid $space-cadet-25;
      cursor: pointer;

      &.active {
        background-color: $mantis;
        color: $just-white;
        border-color: $mantis;

        &:hover {
          background-color: $mantis;
          color: $just-white;
          border-color: $mantis;
        }
      }

      &:hover {
        background-color: $mantis-10;
        color: $mantis;
        border-color: $mantis;
      }
    }

    .mobile-filter-option-group {
      padding-left: 48px;
      position: relative;
      border: 1px solid $space-cadet-25;
      background-color: transparent;
      text-align: left;
      color: $space-cadet;
      font-size: 16px;
      font-family: 'Raleway', sans-serif;
      cursor: pointer;

      &:after {
        content: '\e910';
        font-family: "icomoon" !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        font-size: 12px;
        position: absolute;
        right: 8px;
        top: 22px;
        color: $space-cadet-50;
      }

      .filter-icon {
        width: 32px;
        height: 32px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $just-white;
        position: absolute;
        top: 12px;
        left: 8px;
        background-color: $mantis;
      }
    }
  }
}