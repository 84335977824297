@font-face {
    font-family: 'Bowlby One';
    font-style: normal;
    src: local('Bowlby One'),
        url('../../fonts/BowlbyOne-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: local('Raleway'),
        url('../../fonts/Raleway-Regular.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 400;
    src: local('Raleway italic'),
        url('../../fonts/Raleway-Italic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 700;
    src: local('Raleway bold'),
        url('../../fonts/Raleway-Bold.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 700;
    src: local('Raleway bold italic'),
        url('../../fonts/Raleway-BoldItalic.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    src: local('Raleway light'),
        url('../../fonts/Raleway-Light.ttf') format('truetype');
    font-display: swap;
}

@font-face {
    font-family: 'Raleway';
    font-style: italic;
    font-weight: 300;
    src: local('Raleway light italic'),
        url('../../fonts/Raleway-LightItalic.ttf') format('truetype');
    font-display: swap;
}

h1,
h2,
h3,
h4 {
    font-family: 'Bowlby One', sans-serif;
    color: $text-color;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    word-break: break-word;
}

h1, .h1 {
    font-size: calc(clamp(1.8rem, 3cqw + .5rem, 2.3rem));
    line-height: 1.5;

    @-moz-document url-prefix() {
        font-size: calc(clamp(2rem, 2rem + .5rem, 3.5rem));
    }
}
h2, .h2 {
    font-size: 1.8rem;
    line-height: 48px;

    @include media-breakpoint-up(md) {
        font-size: 40px;
    }

    .subtitle {
        font-size: 32px;
        font-weight: 500;
        color: $blue-jeans;
        display: block;
        font-family: 'Raleway', sans-serif;
    }
}

h3, .h3 {
    font-size: calc(clamp(1.5rem, 2cqw + .5rem, 2.5rem));
    line-height: 40px;
}

h4, .h4 {
    font-size: 24px;
    line-height: 32px;
}

h5,
h6 {
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}

h5, .h5 {
    font-size: calc(clamp(1.2rem, 2cqw + .2rem, 1.5rem));

    @-moz-document url-prefix() {
        font-size: 24px;
    }

    line-height: 32px;
}

h6, .h6 {
    font-size: 16px;
    line-height: 24px;
}

body,
p {
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
}

b {
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
}

body,
p,
b {
    font-size: 16px;
    line-height: 32px;
}

.text-color {
    color: $text-color;
}

a {
    font-family: 'Raleway', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $link-color;

    i {
        color: $link-color;
    }
}

.fw-bold {
    font-weight: bold;
}

.fs-12 {
    font-size: 12px;
}

.fs-14 {
    font-size: 14px;
}

.hl-16 {
    line-height: 16px;
}

.fs-16 {
    font-size: 14px;
}

.fs-24 {
    font-size: 24px;
}

.word-break-all {
    word-break: break-all;
}
