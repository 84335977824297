
.booking-filter {
  padding: 10px;
  margin-bottom: 40px;

  .filter-component {
    justify-content: space-between;
    background-color: $just-white;
    border-radius: 24px;
    padding: 20px;
    display: flex;
    font-size: 16px;
    box-shadow: 0px 8px 48px rgba(26, 30, 64, 0.12);
    margin-bottom: 40px;

    .ts-control, .filter-input input, .ts-dropdown-content .option{
      font-size: 16px;
    }

    .mb-3 {
      margin-bottom: 0 !important;
    }

    form {
        width: 100% !important;
      &>div {
        @media screen and (max-width: 700px) {
          justify-content: center;
          flex-direction: column;
        }
      }
    }

     label {
       display: block;
       font-size: 12px;
       font-weight: bold;
       line-height: 12px;
       color: $santas-gray;
       margin: auto 10px;

       a {
         font-size: 12px;
       }
     }

    .booking-filter_checkbox-wrapper {
      width: 50%;
    }

    .booking-filter_submit-button-wrapper {
      width: 100px;
    }

    .booking-filter_year-wrapper {
      div {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        width: 150px;

        @media screen and (max-width: 700px) {
          width: 100%;
        }
      }
    }

    .booking-filter_booking-wrapper {
      div {
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: 200px;

        @media screen and (max-width: 700px) {
          width: 100%;
        }
      }
    }

    .booking-filter_year-wrapper,
    .booking-filter_booking-wrapper,
    .booking-filter_checkbox-wrapper,
    .booking-filter_submit-button-wrapper {
      @media screen and (max-width: 700px) {
        width: 100%;
        margin-bottom: 20px;
      }
    }

    .booking-filter_submit-button-wrapper {
      display: flex;
      align-items: flex-end;

      button {
        width: 100px;
        @media screen and (max-width: 700px) {
          width: 100%;
        }
      }
    }

    .booking-filter_year-input {
      width: 100px;
      margin: 0;

      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }

    .booking-filter_booking-input {
      width: 150px;
      margin: 0;

      @media screen and (max-width: 700px) {
        width: 100%;
      }
    }

    .booking-filter_year,
    .booking-filter_checkbox {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      align-items: center;

      input[type="checkbox"] {
        height: 20px;
        width: 20px;
        border: orange;
      }

      .label {
        word-wrap: break-word;
        height: 40px;
      }
    }
  }
}
