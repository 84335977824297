.tooltip {
  position: relative;

  &:hover {
    &:after {
      position: absolute;
      left: 0;
      top: 110%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 5px;
      min-height: 20px;
      min-width: 14px;
      background-color: $just-white;
      border: 1px solid $santas-gray;
      color: $text-color;
      padding: 4px 8px;
      content: attr(data-tooltip);
      font-family: Arial, sans-serif;
      font-size: 10px;
      line-height: 12px;
      font-weight: bold;
      outline: 1px solid white;
      width: max-content;
      z-index: 100;
      max-width: 200px;
    }
  }

  &.tooltip--top {
    &:hover {
      &:after {
        top: unset !important;
        bottom: 110%;
      }
    }
  }
}