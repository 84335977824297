.blog-post-card-page {

    a {
        text-decoration: none !important;
    }

    .blog-post-card {
        border: 1px solid $gray-300;
        border-radius: 8px;
        overflow: hidden;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        height: 100%;


        .img-fluid {
            height: 200px;
            object-fit: cover;
            width: 100%;
        }

        &__content {
            padding: 2rem;
            background-color: $white;
        }

        &__title {
            font-size: $h4-font-size;
            font-weight: $font-weight-bold;
            color: $gray-900;
            margin-bottom: 0.5em;

            a {
                text-decoration: none;
                color: inherit;

                &:hover {
                    color: $primary;
                }
            }
        }

        &__excerpt {
            font-size: $font-size-base;
            color: $gray-700;
            margin-bottom: 1em;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        &__date {
            font-size: $small-font-size;
            color: $gray-600;
            position: absolute;
            top: 0;
            right: 1.5rem;
            background-color: $blue-jeans;
            color: $white;
            font-weight: $font-weight-bold;
            padding: 0.5rem 1rem;
            border-radius: 0px 8px 0px 8px;
        }
    }

    .blog-post-wrapper {
        position: relative;
        overflow: hidden;
        transition: all 0.5s ease;
    }
    
    .blog-post-wrapper.expanded {
        width: 100% !important;
    }
    
    .blog-post-card {
        transition: all 0.5s ease;
    }
    
    .read-more-btn {
        display: block;
        color: #007bff;
        text-decoration: none;
        border-radius: 5px;
        position: absolute;
        bottom: calc(2rem + 10px);
    }
}

