.filter-element {
  position: relative;
  max-width: 50vw;

  select {
    border-radius: 16px;
    height: 48px;
    border: 1px solid $space-cadet-25;
    padding: 8px 16px;
    font-family: 'Raleway', sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';

    &:focus {
      outline: 0;
      border: 1px solid $blue-jeans;
    }

    &.with-icon {
      padding-left: 48px;
    }
  }

  .select-icon {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $just-white;
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;

    &.blue {
      background-color: $blue-jeans;
    }

    &.green {
      background-color: $mantis;
    }
  }
}

.ts-wrapper {
  min-width: 130px;

  border-radius: 16px;
  height: 48px;
  border: 1px solid $space-cadet-25;
  background-color: $just-white;
  padding: 8px 16px;
  font-family: 'Raleway', sans-serif;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  cursor: pointer;

  &.group-search-selector {
    .ts-control {
      padding: 0;
      height: 100%;

      input {
        display: block !important;
        height: 100%;
        border-radius: 0;
      }
    }
  }

  .ts-control {
    background-color: transparent !important;
    border: none;
    cursor: pointer;

    input {
      display: none !important;
    }
  }

  &.with-icon {
    .ts-control {
      padding-left: 32px;
    }
  }

  .ts-dropdown {
    border-radius: 12px;
    overflow: hidden;
    padding: 8px;
    border: none;
    width: max-content;

    .option {
      border-radius: 8px;
      margin-bottom: 4px;

      &:last-of-type {
        margin-bottom: 0;
      }
      
      &.selected {
        background-color: $mantis;
        color: $just-white;

        &:hover {
          background-color: $mantis;
          color: $just-white;
        }
      }

      &:hover {
        background-color: $mantis-10;
        color: $mantis;
      }
    }
  }
}

.small-select {
  .ts-wrapper {
    background-color: transparent;
    min-width: 50px;
    border-radius: 8px;
    height: 40px;
    border: 1px solid $space-cadet-25;
    padding: 0 8px;
    font-family: 'Raleway', sans-serif;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
    cursor: pointer;

    .ts-control {
      background-color: transparent !important;
      border: none;
      cursor: pointer;
      padding: 0 16px 0 0;
      line-height: 40px;

      background-image: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");
      background-position: right 0 center;
      background-repeat: no-repeat;
      background-size: 16px 12px;

      input {
        display: none !important;
      }
    }

    &.with-icon {
      .ts-control {
        padding-left: 32px;
      }
    }

    .ts-dropdown {
      border-radius: 12px;
      overflow: hidden;
      padding: 8px;
      border: none;

      .option {
        border-radius: 8px;
        margin-bottom: 4px;

        &:last-of-type {
          margin-bottom: 0;
        }

        &.selected {
          background-color: $coral;
          color: $just-white;

          &:hover {
            background-color: $coral;
            color: $just-white;
          }
        }

        &:hover {
          background-color: $coral-10;
          color: $coral;
        }
      }
    }
  }
}

.multiselect {
  border: 1px solid $athens-gray;
  border-radius: 16px;
  height: 48px;
  box-shadow: none;
  padding: 0 8px;
  display: flex !important;

  &-wrapper {
    width: 100%;
    display: flex;
    padding: 0 10px;
  }

  &-wrapper:has(.multiselect-placeholder) {
    flex-direction: row-reverse;
  }

  &-wrapper:has(.multiselect-single-label) {
    justify-content: space-between;
  }

  &.is-open {
    border-radius: 16px;
  }

  &.with-icon {
    padding-left: 48px;
  }

  &.is-active {
    box-shadow: none;
  }

  .multiselect-single-label {
    position: relative;
    padding: 0 16px 0 0;
  }

  .multiselect-clear {
    display: none;
  }

  .multiselect-dropdown {
    bottom: -5px;
    border-radius: 16px;
    overflow-y: auto;
    width: max-content;

    &::-webkit-scrollbar {
      width: 0.2rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $mantis !important;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: $mantis-10 !important;
      border-radius: 2px;
      margin: 10px 4px 10px 0 ;
    }

    .multiselect-options {
      padding: 4px;
    }

    .multiselect-option {
      margin-bottom: 2px;
    }

    .multiselect-option.is-selected {
      background-color: $coral;
      color: $just-white;
      border-radius: 12px;
    }

    .multiselect-option.is-pointed {
      background-color: $coral-10;
      color: $coral;
      border-radius: 12px;
    }
  }
}