@import 'shared/variables';

// VARIABLES
$header-background: #ffffff;
$header-color: #000000;
$header-height: 48px;

$button-md-height: 48px;
$button-md-border-radius: 16px;
$button-primary-bg-color: $blue-jeans;
$button-primary-color: $just-white;
$button-secondary-bg-color: $coral;
$button-secondary-color: $just-white;
$button-accept-bg-color: $mantis;
$button-accept-color: $just-white;
$button-reject-bg-color: $wild-watermelon;
$button-reject-color: $just-white;

// PACKAGES
@import 'packages/bootstrap-grid/grid';
@import '~@splidejs/splide/dist/css/splide.min.css';
@import '~@splidejs/splide/dist/css/splide-core.min.css';
@import 'packages/multiselect';

@import 'booking-portal/fonts';
@import 'booking-portal/icons';

@import 'shared/overwrite';
@import 'shared/embeded';
@import 'booking-portal/overwrite';
@import 'booking-portal/transitions';

// ELEMENTS
@import 'booking-portal/elements/form';
@import 'booking-portal/elements/button';
@import 'shared/components/form';
@import 'booking-portal/elements/inputs';
@import 'booking-portal/elements/select';
@import 'booking-portal/elements/calendar';
@import 'booking-portal/elements/image';
@import 'booking-portal/elements/checkbox';
@import 'booking-portal/elements/radio';
@import 'booking-portal/elements/badge';
@import 'booking-portal/elements/infobox';
@import 'booking-portal/elements/avatar';
@import 'booking-portal/elements/card';
@import 'booking-portal/elements/switch';
@import 'booking-portal/elements/video';
@import 'booking-portal/elements/couter';
@import 'booking-portal/elements/tooltip';

// COMPONENTS
@import 'booking-portal/components/header';
@import 'booking-portal/components/footer';
@import 'booking-portal/components/tabs';
@import 'shared/components/boxed-icon';
@import 'booking-portal/components/tables';
@import 'booking-portal/components/calendar-widget';
@import 'booking-portal/components/gallery-widget';
@import 'booking-portal/components/mobile-filter';
@import 'booking-portal/components/search-accommodation';
@import 'booking-portal/components/booking-filter';
@import 'booking-portal/components/facet-filter';
@import 'booking-portal/components/price-calculator';
@import 'booking-portal/components/accommodation-mini';
@import 'booking-portal/components/accommodation-label';
@import 'booking-portal/components/booking-mini';
@import 'booking-portal/components/booking-date-widget';
@import 'booking-portal/components/accommodation-prices';
@import 'booking-portal/components/upgrade';
@import 'booking-portal/components/registration-progression-steps';
@import 'booking-portal/components/user-widget';
@import 'booking-portal/components/accordion-table-headers';
@import 'booking-portal/components/check-in-out-times';
@import 'booking-portal/components/content-card';
@import 'booking-portal/components/toast';
@import 'booking-portal/components/timeline';
@import 'booking-portal/components/alerts';
@import 'booking-portal/components/pagination';
@import 'booking-portal/components/document-mini';
@import 'booking-portal/components/breadcrumbs';
@import 'shared/components/modal';
@import 'shared/components/review';
@import 'shared/components/starSelectorWidget';
@import 'shared/components/text-counter';

// BLOCKS
@import 'booking-portal/blocks/home';
@import 'booking-portal/blocks/3-col';
@import 'booking-portal/blocks/text-image';
@import 'booking-portal/blocks/call-to-action';
@import 'booking-portal/blocks/blog-cards';

// PAGES
@import 'booking-portal/pages/pre-auth';
@import 'booking-portal/pages/accommodation-search';
@import 'booking-portal/pages/accommodation-domain';
@import 'booking-portal/pages/account';
@import 'booking-portal/pages/group';
@import 'shared/pages/error';
@import 'shared/pages/info';
@import 'booking-portal/pages/reservation';
@import 'booking-portal/pages/reservation-success';
@import 'booking-portal/pages/review';
@import 'booking-portal/pages/cms';

@import 'shared/pages/registration';
