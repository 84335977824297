.col-3-block{
  position: relative;

  .background-wrapper {
    width: 100%;
    overflow-x: hidden;
  }

  .background-render {
    position: relative;
    min-width: 1800px;
  }

  .outer-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: scroll;
    padding: 0 30px;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar {
      display: none;  /* Safari and Chrome */
    }

    .container {
      height: 100%;
      min-width: 1240px;
      overflow-x: auto;
    }

    .inner-wrapper {
      display: grid;
      align-content: center;
      width: 100%;
      height: 100%;
    }
  }

  .title {
    display: flex;
    margin-bottom: 20px;

    h4 {
      width: calc(100% - 80px - 20px);
      margin-left: 20px;
    }
  }
}