.facet-filter {
  &:first-of-type {
    .filter-head {
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
    }
  }

  &:last-of-type {
    .filter-head {
      &.closed {
        border-bottom-left-radius: 16px;
        border-bottom-right-radius: 16px;
      }
    }

    .filter-body {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      border-bottom: 1px solid $athens-gray-10;
    }
  }

  .filter-head {
    background-color: $space-cadet-75;
    color: $just-white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    line-height: 21px;
    padding: 8px 16px;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;

    .toggler {
      transition: transform .125s ease-in;
      font-size: 12px;
    }
  }

  .filter-body {
    padding: 16px;
    background-color: $just-white;
    visibility: visible;
    opacity: 1;
    transition: visibility .125s ease-in;
    line-height: 21px;
    border-left: 1px solid $athens-gray-10;
    border-right: 1px solid $athens-gray-10;

    &.closed {
      visibility: hidden;
      opacity: 0;
      padding: 0 16px;
      height: 0;
    }

    input[type=number] {
      width: 50px;
      height: 30px;
      border-radius: 8px;
      padding: 4px 8px;
      -moz-appearance: textfield;
      text-align: center;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &:focus {
        border-color: $coral;
      }

    }
  }

}