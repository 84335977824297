.check-in-out-times-wrapper {
  min-height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.check-in-out-times-mobile-title {
  color: $coral !important;

  &.day-exception {
    padding-left: 16px;
  }
}

.check-in-out-times {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 8px;
  position: relative;

  @include media-breakpoint-up(md) {
    align-items: center;
  }

  .check-in-wrapper {
    background-color: $coral;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 50%;
    position: relative;
    margin-right: 5em;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      align-items: center;
    }

    .check-in-values, h5 {
      z-index: 10;
      color: $just-white;
    }

    h5 {
      font-size: 16px;
    }

    small {
      font-size: 14px;
      line-height: 14px;
      color: $coral-10;
    }

    .check-in-times {
      font-size: 20px;
      font-weight: bold;
    }

    .angle {
      display: block;
      height: calc(70.71% + 4.6px);
      background-color: $coral;
      position: absolute;
      top: calc(14% - 1.6px);
      right: 0;
      border-radius: 8px;
      transform: rotate(45deg) translateY(-35%) translateX(35%);
      z-index: 1;

      .scaling-ref {
        height: 100%;
        opacity: 0;
      }
    }
  }

  .check-out-wrapper {
    padding-bottom: 10px;
    @include media-breakpoint-up(md) {
     padding-bottom: 0;
    }
    small {
      font-size: 14px;
      line-height: 14px;
      color: $coral;
    }

    .check-in-times {
      font-size: 20px;
      font-weight: bold;
      color: $coral-125;
    }
  }

  small {
    display: block;
  }

  &.day-exception {
    padding-left: 1em;

    .check-in-wrapper {
      background-color: $coral-25;
      width: calc(50% - 0.5em);

      .angle {
        background-color: $coral-25;
      }

      h5 {
        color: $coral;
      }

      .check-in-values {
        color: $coral-125;
      }

      small {
        color: $coral-75
      }
    }
  }
}