.review-element {
  background-color: $naples-yellow-10;
  border: 1px solid $naples-yellow-50;
  border-radius: 24px;
  box-sizing: border-box;
  padding: 24px;
  position: relative;
  container-type: inline-size;

  &.reply {
    margin-top: 1em;
    margin-left: calc(min(15vw, 130px));

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      top: calc(-1em - 1px);
      left: -50px;
      width: 50px;
      height: calc(.5em + 50% - 1px);
      border-left: 2px solid $naples-yellow-50;
      border-bottom: 2px solid $naples-yellow-50;
      border-bottom-left-radius: 40px;
    }
  }

  h2 {
    margin-bottom: .5em;
  }

  .review-title {
    a {
      text-decoration: none;
      color: unset;
      font-size: unset;
      line-height: unset;
    }
  }

  .rating-element{
    background-color: white;
    box-shadow: 0 6px 12px rgba(26, 30, 64, 0.08);
    padding: 8px 16px;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 12px;
    margin-bottom: 1.5em;
    font-size: calc(clamp(0.7rem, 2cqw, 1rem));

    @-moz-document url-prefix() {
      font-size: 1rem;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .title {
      color: #999BAA;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  p {
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 1em;
  }

  .remarks {
    padding: 0;

    li {
      font-size: 16px;
      padding: 10px 0;
      display: flex;
      align-items: self-start;

      &:before {
        font-family: '#{$icomoon-font-family}' !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;

        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        font-size: 24px;
        margin-right: 1em;
        margin-top: 4px;
      }

      &.positive {
        &:before {
          content: $icon-positive;
          color: $mantis;
        }
      }

      &.negative {
        &:before {
          content: $icon-negative;
          color: $wild-watermelon;
        }
      }
    }
  }
}

.rating {
  display: inline-flex;
  flex-direction: row;
  align-items: center;

  i {
    font-size: 16px;
    margin-right: .5em;
  }
}

.prosFields, .consFields {
  label {
    display: none !important;
  }

  .field .form-group:first-child {
    display: flex;
    align-items: center;
    width: 100%;

    > div {
      width: 100%;

      .mb-3 {
        margin-bottom: 0 !important;
      }
    }

    &:before {
      font-family: '#{$icomoon-font-family}';
      font-size: 20px;
      margin-right: 1em;
    }
  }
}

.prosFields {
  .field .form-group:first-child {
    &:before {
      content: $icon-positive;
      color: $mantis;
    }
  }
}

.consFields {
  .field .form-group:first-child {
    &:before {
      content: $icon-negative;
      color: $wild-watermelon;
    }
  }
}
