.call-to-action-block {
  background-color: $naples-yellow-25;
  border-radius: 32px;
  padding: 24px;
  margin-top: 180px;

  @include media-breakpoint-up(md) {
    padding: 80px;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 120px;
  }

  .image-wrapper {
    position: relative;
    margin-bottom: 40px;
    margin-top: -180px;

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: -120px;
      right: 0;
      height: calc(100% + 240px);
      margin-bottom: 0;
      margin-top: 0;
    }
  }


}