.registration-progression-steps {
  display: inline-flex;
  align-items: center;

  .registration-progression-step {
    position: relative;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $mantis-50;
    color: $just-white;
    font-family: 'Bowlby One', sans-serif;
    font-size: 24px;
    line-height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 48px;

    &:after {
      content: '';
      position: absolute;
      top: calc(50% - .5px);
      right: -35px;
      height: 1px;
      width: 24px;
      background-color: $space-cadet-25;
    }

    &:last-of-type {
      margin-right: 0;

      &:after {
        content: none;
      }
    }

    &.current-step {
      width: 56px;
      height: 56px;
      font-size: 32px;
      line-height: 48px;
      background-color: $mantis;
      box-shadow: 0 0 8px $mantis-50;
      border: 4px solid $mantis-50;
      outline: 3px solid $mantis-25;

      &:after {
        right: -40px;
      }
    }
  }
}