.toggle-container {
  .toggle {
    height: 10px;
    width: 50px;
    border: 1px solid $coral-10;
    background-color: $coral-10;
    border-radius: 4px;
    display: flex;
    justify-content: flex-end;
    transition: all ease-in .5s;
    position: relative;

    &.toggle-off {
      justify-content: flex-start;
      border: 1px solid $athens-gray-10;
      background-color: $athens-gray-10;
    }

    .toggle-handle {
      background-color: $coral;
      border-radius: 5px;
      height: 16px;
      width: 30px;
      margin: -4px 0px;
      display: block;
      transition: all .125s ease;
      transform: translate(calc(100% - 25px), 0);

      &.toggle-handle-off {
        transform: translate(-1px, 0);
        background-color: $space-cadet-25;
      }
    }
  }
}

.switch-group {
  display: flex;
  align-items: center;
  gap: 8px;

  label {
    font-size: 12px;
  }
}

.switch {
  display: none;

  // add default box-sizing for this scope
  &,
  &:after,
  &:before,
  & *,
  & *:after,
  & *:before,
  & + .switch-btn {
    box-sizing: border-box;
    &::selection {
      background: none;
    }
  }

  + .switch-btn {
    outline: 0;
    display: block !important;
    width: 2.3em;
    height: 1.5em;
    position: relative;
    cursor: pointer;
    user-select: none;
    padding: 2px;
    transition: all .2s ease;
    background: #fff;
    border: 2px solid $santas-gray;
    border-radius: 2em;

    &:after,
    &:before {
      position: relative;
      display: block;
      content: "";
      width: 50%;
      height: 100%;
    }

    &:after {
      left: 0;
      transition: all .2s ease;
      background: $mischka;
      content: "";
      border-radius: 1em;
    }

    &:before {
      display: none;
    }
  }

  &:checked + .switch-btn {
    border: 2px solid $coral;

    &:after {
      left: 50%;
      background: $coral;
    }
  }
}
