.fadeIn {
  opacity: 0;
  animation: 1s ease-out .5s 1 fadeIn forwards;
}

.hover {
  animation: 2s ease-in-out .5s infinite hover;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translate3d(0, 0, 0)
  }
  100% {
    transform: translate3d(0, -5%, 0)
  }
}

@keyframes hover {
  0% {
    transform: translate3d(0, 0, 0)
  }
  50% {
    transform: translate3d(0, 8px, 0)
  }
  100% {
    transform: translate3d(0, 0, 0)
  }
}

@keyframes stop {
  0% {
    transform: rotate(0);
  }
  40% {
    transform: rotate(-20deg);
  }
  100% {
    transform: rotate(0);
  }
}