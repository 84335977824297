.accordion-table-header {
  height: 65px;
  position: relative;
  cursor: pointer;

  &:hover {
    i {
      transform: rotate(-180deg);
    }
  }

  i {
    color: $space-cadet-25;
    position: absolute;
    left: 0;
    top: calc(50% - 8px);
  }

  .accordion-title {
    position: absolute;
    left: calc(min(8%, 30px));
    transform: translate3d(0, 0, 0);
    transition: all 1s ease;
    color: $text-color;

    &.center {
      left: 50%;
      transform: translate3d(-50%, 0, 0);
    }
  }
}