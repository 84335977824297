.upgrade-element {
  background-color: $mantis-10;
  border-radius: 24px;
  max-width: 800px;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .upgrade-description {
    width: 100%;
    padding-right: 16px;
    border-right: 0;
    text-align: center;

    @include media-breakpoint-up(md) {
      width: 75%;
      border-right: 1px solid $mantis-25;
      text-align: left;
    }
  }

  .upgrade-price {
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-top: .5em;

    @include media-breakpoint-up(md) {
      margin-top: 0;
      width: 25%;
    }

    b {
      font-size: 18px;
    }
  }

  p {
    margin-top: 16px;
    color: $space-cadet;
  }

  .tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: $mantis-125;
    background-color: $mantis-25;
    border-radius: 8px;
    padding: 8px;
    margin-top: 8px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 14px;

    i {
      margin-right: 8px;
      color: $mantis;
    }

  }
}