.boxed-icon {
    height: 32px;
    min-width: 32px;
    border-radius: 8px;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: $manager-portal-lightgray;
    color: $just-white;

    i {
        font-size: 16px;
    }

    &.small {
        height: 30px;
        width: 30px;
    }

    &.medium {
        height: 40px;
        width: 40px;
    }

    &.xl-medium {
        @include media-breakpoint-up(xl) {
            height: 40px;
            width: 40px;
        }
    }

    &.large {
        height: 80px;
        width: 80px;
        border-radius: 24px;

        i {
            font-size: 40px;
        }
    }

    &.darken {
        i {
            color: rgba($text-color, 0.4) !important;
        }
    }

    &.green {
        background-color: $mantis;

        i {
            color: $just-white;
        }
    }

    &.lightgreen {
        background-color: $mantis-10;

        i {
            color: $mantis;
        }
    }

    &.blue {
        background-color: $blue-jeans;

        i {
            color: $just-white;
        }
    }

    &.lightblue {
        background-color: $blue-jeans-10;

        i {
            color: $blue-jeans;
        }
    }

    &.orange {
        background-color: $coral;

        i {
            color: $just-white;
        }
    }

    &.lightorange {
        background-color: $coral-10;

        i {
            color: $coral;
        }
    }

    &.red {
        background-color: $wild-watermelon;

        i {
            color: $just-white;
        }
    }

    &.lightred {
        background-color: $wild-watermelon-10;

        i {
            color: $wild-watermelon;
        }
    }

    &.yellow {
        background-color: $naples-yellow;

        i {
            color: $just-white;
        }
    }

    &.lightyellow {
        background-color: $naples-yellow-10;

        i {
            color: $naples-yellow-125;
        }
    }

    &.lightpurple {
        background-color: $lavender-purple-10;

        i {
            color: $lavender-purple;
        }
    }
}
