input, textarea {
  border-radius: 16px;
  border: 1px solid $space-cadet-25;
  padding: 8px 16px;
  font-family: 'Raleway', sans-serif;

  &:focus {
    outline: 0;
    border: 1px solid $blue-jeans;
  }
}

input {
  height: 48px;
}

textarea {
  width: 100%;
  resize: vertical;
}

label {
  position: relative;
  display: inline-block !important;
}

label.required {
  &:after {
    content: ' ';
    position: absolute;
    top: 0px;
    right: -16px;
    display: block;
    margin-right: 3px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $wild-watermelon;
  }
}